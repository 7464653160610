.link-list {
    li {
        color: $link-list-item-color;
    }
    &_inline {
        &,
        &-ordered,
        &-plain {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;

            li {
                display: block;
                margin-left: $link-list-item-margin-x;
                margin-right: $link-list-item-margin-x;
                box-sizing: border-box;
            }
            .text-center > & {
                justify-content: center;
            }
            .text-right > & {
                justify-content: flex-end;
            }
        }
        &-plain {
            li{
                margin-left: 0;
                
                &:before {
                    content: normal;
                }
            }
        }
        &-ordered {
            li {
                width: 25%;
                .link {
                    display: inline-block;
                    text-align: left;
                    width: 100%;
                }
            }
            
            @media (min-width: $screen-s) {
                li{
                    width: auto;
                    .link {
                        display: inline;
                        min-width: auto;
                        min-width: auto;
                    }
                }
                
            }
        }
    }
    &_icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-left: 0;

        li {
            margin: $link-list-icons-item-margin;
        }
    }
    &_icons,
    &_icon-row {
        li:before {
            content: normal;
        }
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
        .link-title {
            padding-left: 0;
            padding-right: 0;
            display: block;
        }
    }
    &_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    &_icon-row,
    &_row {
        &,
        &_half {
            @extend .block-row;
            .link-list-item {
                @extend .col-6;
                @extend .col-s-4;
            }
        }
        .link-list-item {
            @extend .col-sm-3;
            @media (min-width: $screen-md) and (max-width: $screen-md-max){
                width: 20%;
            }
            @extend .col-lg-2;
        }
        &_half .link-list-item {
            @extend .col-sm-3;
            @extend .col-md-4;
            @extend .col-lg-4;
            @extend .col-xl-3;
        }
    }
    &_icon-row_half,
    &_icon-row {
        .link-title {
            hyphens: auto;
            text-align: center;
            line-height: $link-list-title-line-height;
            min-height: $link-list-title-height;
            height: $link-list-title-height;
            overflow: visible;
        }
        .link-icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 auto;
        }
        a {
            min-height: 100%;
        }
    }
    &_light,
    &_light-delimited {
        a {
            &,
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                color: $link-list-light-link-color;
            }
        }
    }
    &_light-delimited {
        text-align: center;
    }
    &_light-delimited,
    &_delimited {
        display: flex;
        justify-content: center;
        overflow: hidden;
        margin-bottom: $link-list-delimited-margin-bottom;
        position: relative;

        .link-list-item {
            margin-bottom: $link-list-delimited-item-margin-bottom;
            padding-top: $link-list-delimited-item-padding-top;
            &:before {
                @media (min-width: 240px){
                    content: "";
                    position: absolute;
                    top: auto;
                    width: auto;
                    left: $link-list-delimited-item-delimiter-spacing-x;
                    right: $link-list-delimited-item-delimiter-spacing-x;
                    @include delimiterBorder();
                    margin: $link-list-delimited-item-delimiter-margin;
                }
                @media (min-width: $screen-md) {
                    right: $link-list-delimited-item-delimiter-md-spacing-x;
                    left: $link-list-delimited-item-delimiter-md-spacing-x;
                }
            }
        }
    }
    &_w{
        &80 {
            > li {
                min-width: 80px;
            }
        }
        &100 {
            > li {
                min-width: 100px;
            }
        }
        &120 {
            > li {
                min-width: 120px;
            }
        }
        &-half {
            > li {
                width: calc(50% - 1rem);
                margin-right: 0;
            }
        }
    }
    &.slider-md,
    &.slider-lg {
        &:after {
            content: "";
            display: none;
        }
    }
    &.slider-enabled {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        flex-direction: row;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        height: auto;
        padding-left: 0;
        margin-left: 0;
// link-list link-list_icon-row_half link-list_light-delimited slider-md slider-lg js-slider-list slider-enabled
        &.link-list_icon-row,
        &.link-list_icon-row_half {
            .link-list-item-dots,
            .link-list-item-arrows {
                margin-left: 10px;
            }
        }
        .link-list-item {
            display: block;
            width: 100%;

            &-dots {
                @extend .banner-slide-dots;
                justify-content: center;
            }
            &-dot {
                @extend .banner-slide-dot;
            }
            &-arrows {
                display: block;
            }
        }
    }
    .link-list-item {
        &-arrows {
            @extend .slider-arrows;
        }
        &-arrow {
            @extend .slider-arrow;
            &_prev {
                @extend .slider-arrow_prev;
            }
            &_next {
                @extend .slider-arrow_next;
            }
        }
        &-arrows,
        &-dots {
            display: none;
        }
        
    }
    @media (min-width: $screen-md){
        .link-list-item {
            &-arrows{
                left: -10px;
                right: -10px;
            }
        }
    }
    
    @media (min-width: $screen-md) and (max-width: $screen-md-max){
        &.slider-md {
            &:after {
                content: "slider";
                height: 150px;
                min-width: 2px;
            }
            .link-list-item {
                // position: absolute;
                // z-index: -1;
                body &-dots {
                    justify-content: center;
                }
            }
        }
    }
    @media (min-width: $screen-lg) and (max-width: $screen-lg-max){
        &.slider-lg {
            &:after {
                content: "slider";
                height: 160px;
                min-width: 2px;
            }
            div.link-list-item {
                // position: absolute;
                // z-index: -1;
                body &-dots {
                    justify-content: center;
                }
            }
        }
    }
}