//
// Headings
//
h1,.h1 {
  font-size: $h1-font-size;
  color: $headings-color;
}

h2,.h2 {
  font-size: $h2-font-size;
  color: $headings-color;
  font-weight: $font-weight-bold;
}

h3,.h3 {
  font-size: $h3-font-size;
  color: $headings-color;

  @if $h3-font-size-mobile {
    @media (max-width:$screen-sm-max){
      font-size: $h3-font-size-mobile;
    }
  }
}

h4,.h4 {
  font-size: $h4-font-size;
  color: $headings-color;
  font-weight: $font-weight-bold;
}

h5,.h5 {
  font-size: $h5-font-size;
  color: $headings-color;
  font-weight: $font-weight-normal;
}

h6,.h6 {
  font-size: $h6-font-size;
  color: $headings-color;
}

//
// Emphasis
//

.small {
  @extend small;
}

.mark {
  @extend mark;
}

.font-normal {
  font-weight: $font-weight-normal;
}
b,
strong,
.font-bold,
.strong {
  font-weight: $font-weight-bold;
}
.font-italic {
  font-style: italic;
}
.h1, .h2, .h3, .h4, .h6 {
  &_light {
    @extend .font-normal;
  }
  &_text {
    @extend .font-normal;
    @extend .color-default;
  }
}
.h5 {
  &_strong {
    @extend .font-bold;
  }
  &_text {
    @extend .color-default;
  }
}

// Blockquotes
blockquote,
.blockquote {
  margin-bottom: $spacer;
  padding-left: $spacer;
  padding-right: $spacer;
  box-sizing: border-box;
  border-left: 0.2rem solid $border-color;
}

// Text-Heading
.text-heading {
  display: block;

  &_dotted {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 1px;
      flex: 1 1 auto;
      border-top: 1px dotted $dark;
      box-sizing: border-box;
    }
    span {
      border: ($spacer * 0.5) solid transparent;
      border-width: 0 ($spacer * 0.5);
    }
  }
}

.headline {
  display: flex;
  align-items: center;
  span {
    flex: 1 1 auto;
  }
  &-icon {
    max-height: 38px;
    width: auto;
    display: block;
  }
  &_text {
    span {
      @extend .color-default;
    }
  }
}

.color-default {
  color: $body-color;
}

.hyphens-auto {
  hyphens: auto;
  word-wrap: break-word;
}