//card
.card {
	display: block;
    color: $body-color;
    text-decoration: none;

    &-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        img {
            max-width: 100%;
            height: auto;
        }
        &_right {
            background-position: center right;
        }
        &_left {
            background-position: center left;
        }
    }
    &-content {
        @extend .bg_gray;

        &:first-child {
            min-height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &-body {
        padding: $spacer $spacer * 0.625;
        flex: 1 0 auto;
        @media (min-width: $screen-md){
            padding: $spacer;
        }
    }
    &-content_stretch &-body {
        display: flex;
        flex-direction: column;

        .card-bottom {
            flex: 1 0 auto;
            align-items: flex-end;
        }
    }
    &-content_full &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    &_bordered {
        border: 1px solid $border-color;
    }
    &_complex &-headline {
        margin-bottom: $spacer * 1.25;
    }
    &-bottom {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-end;
    }
    &-list > &,
    .row > & {
        @extend .col-md-6;
    }
    .section-cards &,
    .cards & {
        @extend .col-md-6;
        margin-bottom: $spacer;
        margin-top: $spacer;
    }
    .section-cards_plain &-content,
    .cards_plain &-content {
        background: transparent;
    }
    &_full {
        min-width: 100%;
    }
    @media (min-width: $screen-sm){
        &_image-right,
        &_image-left {
            display: flex;
            align-items: stretch;
            
            .card-image {
                min-height: 140px;
                flex: 1 1 auto;
                max-height: 100%;
                overflow: hidden;
                position: relative;
                display: block;
                
                &[style*="background-image:"] {
                    .card-img {
                        opacity: 0;
                        height: auto;
                    }
                }
                &_half {
                    min-width: 50%;
                }
                &_mw30 {
                    min-width: 30%;
                }
                &_mw40 {
                    min-width: 40%;
                }
            }
            .card-img {
                position: relative;
                display: block;
                min-width: 100%;
                width: auto;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%,-50%,0);
            }
        }
        &_image-right {
            flex-direction: row-reverse;
        }
    }
}
a.card {
    &:hover,
	&:focus {
		border-color: $gray-dark;
        background: $white-soft;
        &,
        * {
            text-decoration: none;
        }
    }
}
.cards {
    @extend .block-row;
    margin-top: $spacer * -1;
    margin-bottom: $spacer * -1;
}