.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zindex-fixed;

    &-container {
        display: flex;
        justify-content: space-between;
        background-color: $header-container-bg-color;

        .logo_img {
            max-height: 32px;
        }
        .logo {
            padding: ($spacer * 0.375) ($spacer * 0.625);
        }
        .nav-list {
            &:last-child {
                margin-right: ($spacer * 0.625) * -1;
            }
            .nav-list-item:before {
                content: normal;
            }
        }
        @media (min-width: $screen-md) {
            .logo {
                padding-bottom: ($spacer * 0.625);
                &_img {
                    max-height: 78px;
                }
            }
        }
    }
    &-nav {
        background-color: $header-md-nav-bg-color;
        position: relative;
        z-index: $zindex-fixed;

        .nav-list {
            &_side {
                position: fixed;
            }
        }
        .nav {
            background-color: transparent;
        }
    }
    &-nav .nav,
    &-container {
        @media (min-width: $screen-md) {
            margin-left: auto;
            margin-right: auto;
            max-width: map-get($_container-max-widths, "md");
        }
        @media (min-width: $screen-lg) {
            padding-left: $container-padding-x;
            padding-right: $container-padding-x;
            max-width: map-get($_container-max-widths, "lg");
        }
        @media (min-width: $screen-xl) {
            max-width: map-get($_container-max-widths, "xl");
        }
        @media (min-width: $screen-xxl) {
            max-width: map-get($_container-max-widths, "xxl");
        }
    }
    
    @media (max-width: $screen-sm-max){
        &_side-nav-desktop .nav-list_side {
            display: none;
        }
    }

    @media (min-width: $screen-md){
        position: static;
        &-nav {
            .nav {
                background-color: $header-md-nav-bg-color;
                justify-content: space-between;
            }
            .nav-list {
                &_side {
                    position: relative;
                    top: auto;
                    bottom: auto;
                    transform: none;
                }
            }
            .nav-dropdown {
                width: $header-dropdown-width;
            }
        }
        &-container {
            overflow: hidden;
            height: auto;
        }
        &-container .nav-list_inline {
            padding-right: $container-padding-x;
        }
        &_aboveBanner &-nav {
            position: relative;
            .nav-list_main > .nav-list-node-item {
                position: static;
            }
            // .nav-list-node-item.nav-list-item_primary:hover {
            //     &:before {
            //         content: "";
            //         background-color: $gray-beige;
            //         position: absolute;
            //         height: 280px;
            //         z-index: -1;
            //         width: 100vw;
            //         left: 0;
            //         bottom: 0;
            //         opacity: 0.8;
            //         transform: translate3d(0, 100%, 0);
            //     }
            // }
        }
    }
    @media (min-width: $screen-lg) {
        &-container .nav-list_inline {
            padding-right: 0;
        }
    }
}