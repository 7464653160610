.subMenuItem {
    .nav-link {
        text-decoration: none;
        &,
        &:hover,
        &:active,
        &:focus {
            color: inherit;
        }
    }
}
.nav {
    &_submenu {
        background: transparent;
        color: auto;
        padding: ($spacer * 1.25) $container-padding-x;
        flex-direction: column;
        .subMenuUpperItem, // added temporarily from previous markup //
        > .nav-link {
            display: block;
            color: $header-nav-active-color;
            font-weight: $font-weight-bold;
            &,
            &:hover,
            &:active {
                text-decoration: none;
            }
        }
        .nav-list {
            margin-top: $spacer * 0.25;
            display: block;
        }
        .nav-list {
            .nav-list-item {
                &.selected,
                &:hover {
                    color: $header-nav-active-color;
                }
            }
        }
        .nav-list .nav-list-item {
            .nav-link {
                &:active,
                &:focus,
                &:hover {
                    color: inherit;
                }
            }
        }
    }
    &_tabs {
        display: flex;
        flex-wrap: nowrap;
        background-color: transparent;

        .nav-list {
            display: flex;
            flex-wrap: nowrap;
            background-color: transparent;
            padding: 4px 0 0;
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 1em;
            }

            &-item {
                padding: 0 2px 0 0;
                display: block;
                margin: 0;
                min-width: 100px;

                &:before {
                    content: normal;
                }
                .nav-link {
                    padding: 10px 20px 4px 10px;
                    line-height: 1.4;
                    display: block;
                    width: 100%;
                    font-weight: $font-weight-bold;
                    color: $tab-bar-item-color;
                    background-color: $tab-bar-item-bg;
                }
                &.active {
                    .nav-link {
                        margin-top: -4px;
                        padding-top: 14px;
                        color: $tab-bar-item-active-color;
                        background-color: $tab-bar-item-active-bg;
                    }
                }
            }
            &_steps {
                padding: 0;
                width: 100%;

                .nav-link {
                    padding: $input-btn-padding-y 16px $input-btn-padding-y 28px;
                    position: relative;
                    &:after,
                    &:before {
                        content: "";
                        border: 20px solid transparent;
                        border-width: 20px 0 20px 12px;
                        border-left-color: $body-bg;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate3d(0%,-50%,0);
                        z-index: 0;
                    }
                    &:after {
                        border-left-color: $tab-bar-item-bg;
                        left: auto;
                        right: 0;
                        transform: translate3d(100%,-50%,0);
                        z-index: 1;
                    }
                }

                .nav-list-item {
                    padding-right: 4px;
                    min-width: 120px;
                    flex: 1 1 auto;

                    &:last-child {
                        padding-right: 0;
                    }
                    &.active .nav-link {
                        padding-top: $input-btn-padding-y;
                        margin-top: 0;
                        &:after {
                            border-left-color: $tab-bar-item-active-bg;
                        }
                    }
                    &:last-child .nav-link:after,
                    &:first-child .nav-link:before {
                        content: normal;
                    }
                    &:last-child .nav-link {
                        padding-right: 16px;
                    }
                    &:first-child .nav-link {
                        padding-left: 16px;
                    }
                }
                
                @media (max-width: $screen-sm-max){
                    &-mobile-active-only {
                        justify-content: center;
                        counter-reset: list-steps-counter;

                        .nav-list-item {
                            counter-increment: list-steps-counter;
                            display: none;
                            &:before {
                                font-family: $font-name-base;
                                display: block;
                                transform: none;
                                box-sizing: border-box;
                                position: static;
                                width: 56px;
                                height: 56px;
                                border-radius: 50%;
                                box-sizing: border-box;
                                line-height: 52px;
                                font-size: 35px;
                                color: $tab-bar-item-active-color;
                                background-color: $tab-bar-item-active-bg;
                                text-align: center;
                                display: block;
                                margin: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            &.active {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                &:before {
                                    content: counter(list-steps-counter);
                                }
                            }
                            .nav-link {
                                color: $body-color;
                                text-align: center;
                                width: auto;
                                background: transparent;
                                &:before,
                                &:after {
                                    content: normal;
                                }
                            }
                        }
                    }
                    &-mobile-bottom {
                        position: fixed;
                        bottom: 0;
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        left: 0;
                        z-index: $zindex-fixed;
                        color: $header-nav-bottom-sticky-color;
                        background-color: $header-nav-bottom-sticky-bg-color;
                        padding-bottom: $spacer * 0.625;
                        max-width: 100%;
                        padding-left: $spacer * 0.375;
                        padding-right: $spacer * 0.375;

                        &:after {
                            content: "";
                            border-top: 1px solid #808285;
                            height: 1px;
                            position: absolute;
                            bottom: $spacer * 0.625;
                            left: $spacer * 0.625;
                            right: $spacer * 0.625;
                            z-index: -1;
                            margin-bottom: -1px;
                        }
                        .nav-list-item {
                            padding: 0 $spacer * 0.25;
                            flex: 1 1 auto;
                            min-width: auto;
                            overflow: hidden;
                            line-height: 0;
                            position: relative;              

                            .nav-link{
                                padding-left: 0;
                                padding-right: 0;
                                line-height: 1;
                                background-color: transparent;
                                white-space: nowrap;
                                position: static;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: inline-block;
                                width: auto;
                                max-width: 100%;
                                box-sizing: border-box;
                                border-bottom: 1px solid transparent;

                                &:before {
                                    content: "";
                                    left: 1px;
                                    right: 1px;
                                    top: 0;
                                    bottom: 0;
                                    border: none;
                                    transform: none;
                                    z-index: 0;
                                    height: auto;
                                    width: auto;
                                    position: absolute;
                                }
                                &:after {
                                    content: normal;
                                }
                            }
                            &.active .nav-link {
                                background-color: transparent;
                                border-bottom-color: $white;
                                // &:after {
                                //     content: "";
                                //     border: 1px solid $white;
                                //     border-width: 1px 0 0;
                                //     bottom: 0;
                                //     left: 0;
                                //     right: 0;
                                //     position: absolute;
                                //     transform: none;
                                //     top: auto;
                                // }
                            }
                            &:first-child .nav-link {
                                padding-left: 0;
                                &:before {
                                    content: "";
                                }
                            }
                            &:last-child .nav-link {
                                padding-right: 0;
                            }
                        }
                        .header_mobile-side-nav-bottom ~ main & {
                            bottom: 54px;
                        } 
                    }
                }
                @media (min-width: $screen-s) and (max-width: $screen-sm-max){
                    &-mobile-bottom {
                        padding-left: $spacer * 0.125;
                        padding-right: $spacer * 0.125;
                        .nav-list-item {
                            padding-left: $spacer * 0.5;
                            padding-right: $spacer * 0.5;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: $screen-md){
        display: flex;
        max-width: 100%;
        padding: 0;

        &_primary {
            background-color: $header-md-nav-bg-color;
            justify-content: space-between;
        }        
        &-list_main &-link {
            display: block;
            padding-top: ($spacer * 0.125);
            padding-bottom: ($spacer * 0.125);
            &:after {
                display: none;
            }
        }
        &-list_main &-link_primary {
            display: block;
            white-space: nowrap;
            flex: 0 1 auto;
            font-size: $font-size-lg;
            padding-top: $spacer * 0.825;
            padding-bottom: $spacer * 0.825;
            &:before {
                display: none;
            }
            &:after {
                display: block;
            }
        }
        &-list {
            &_main {
                display: flex;
                align-items: stretch;
                margin: 0;
                padding-top: 0;
                padding-bottom: 0;
                line-height: 0;
                flex: 0 1 auto;
                flex-wrap: nowrap;
            }
            &_main & {
                position: absolute;
                display: none;
                min-width: 200px;
                padding: ($spacer * 1.15) ($spacer * 0.625) ($spacer * 1.25);
                background-color: $header-md-subnav-bg-color;
                color: $header-md-subnav-color;
                .nav-link {
                    padding-left: $spacer * 1;
                    @include icon-glyph('arrow_right-light') {
                        position: absolute;
                        margin-left: $spacer * -1;
                        font-size: $font-size-sm;
                        line-height: 1.28;
                    }
                    &:hover {
                        color: $header-nav-hover-color;
                    }
                }
                .nav-list-item {
                    padding: 0.075 * $spacer 0;
                }
            }
            &_main &-item_primary {
                display: block;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                height: 100%;
                border: none;
                line-height: 1;
                color: $header-md-nav-color;
                flex: 0 1 auto;

                &.active .nav-link_primary {
                    color: inherit;
                    font-weight: $font-weight-bold;
                }
                &.nav-list-node-item .nav-link_primary {
                    padding-right: $spacer *1.625;
                    &:after {
                        right: $spacer * 1.5;
                    }
                }
                &:hover > .nav-list {
                    display: block;
                }
            }
            &_side &-item,
            &_main &-item_primary {
                &:hover {
                    background-color: $header-md-nav-active-bg-color;
                    color: $header-md-nav-active-color;
                }
            }
            &_main > & {
                &-node-item {
                    position: relative;
                    a {
                        padding-right: $spacer * 1.35;
                        &:after {
                            right: $spacer * 1.25;
                        }
                    }
                    &:hover {
                        > .nav-list {
                            display: block;
                            position: absolute;
                            bottom: 0;
                            transform: translate3d(0, 100%, 0);
                            height: auto;
                            width: auto;
                        }
                    }
                }
            }
            &_main &_sub {
                display: none;
            }
            &_side {
                margin: 0;
                align-items: stretch;
                justify-content: flex-end;
                padding: 0;
                position: relative;
            }
            &_side &-item {
                padding: ($spacer * 0.125) ($spacer * 0.3625);
                display: flex;
                align-items: center;
                
                .icon {
                    width: 24px;
                    height: 24px;
                    font-size: 22px;
                    line-height: 1;
                    
                    &_img {
                        max-height: 24px;
                    }
                }
                .label {
                    font-size: $font-size-sm;
                    padding: 0;
                }
                &.hidden-md {
                    display: none;
                }
                .nav-link {
                    padding: ($spacer * 0.25) $spacer * 0.25 ($spacer * 0.125);
                    line-height: 1;
                }
            }
        }
        &-dropdown {
            padding: ($spacer * 1.25);
        }
        &-list-item:hover &-dropdown {
            display: block;
            right: 0;
        }
        &_submenu {
            padding: ($spacer * 1.25) $container-padding-x;
        }
    }
    @media (min-width: $screen-lg) {
        &-list_side &-list-item {
            padding: ($spacer * 0.125) ($spacer * 0.5);
            min-width: 80px;
            
            .label {
                padding: 0;
                display: block;
            }
        }
    }
    @media (max-width: $screen-sm-max){
        &-list {
            .header-nav &_main,
            &-node-item > .nav-list {
                display: none;
            }
            .header-nav &_main.active,
            &-node-item.active > .nav-list {
                display: block;
            }
            @if $header-nav-passive-enabled {
                &-node-item.passive > .nav-list {
                    display: block;
                }
            }
            .header-nav &_main {
                max-height: $header-nav-max-height;
                overflow: auto;
            }
        }
    }
}
