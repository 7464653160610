.section {
    margin-top: $spacer * 1.25;
    margin-bottom: $spacer * 1.25;
    &_slim {
        &,
        &-top {
            margin-top: $spacer;
        }
        &,
        &-bottom {
            margin-bottom: $spacer;
        }
    }
    &_large {
        &,
        &-top {
            margin-top: $spacer * 4;
        }
        &,
        &-bottom {
            margin-bottom: $spacer * 4;
        }
    }
    @media (min-width: $screen-md){
        margin-top: $spacer * 2.5;
        margin-bottom: $spacer * 2.5;
        &_slim {
            margin-top: $spacer * 1.25;
            margin-bottom: $spacer * 1.25;
        }
        &_large {
            margin-top: $spacer * 5;
            margin-bottom: $spacer * 5;
        }
    }
    &_plain {
        &,
        &-top{
            margin-top: 0;
        }
        &,
        &-bottom {
            margin-bottom: 0;
        }
    }
    &,
    &_large,
    &_slim {
        &:first-child {
            margin-top: 0;
        }
    }
    &-headline {
        &,
        &-container {
            margin-bottom: $spacer * 2;

            span:not([class]) {
                @extend .color-default;
            }
        }
    }
    &-headline-container &-description {
        margin-top: $spacer * 1.25;
    }
    & > &-headline-container {
        &:first-child {
            padding-top: $spacer * 1.25;
        }
    }
    &_cards {
        @extend .cards;
        margin-top: $spacer * 1.5;
        margin-bottom: $spacer * 2;
        // margin-bottom: 0;

        @media (max-width: $screen-sm-max){
            .container > & > .card {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &_icon-tease-mobile {
        @extend .bg_light;
        @media (max-width: $screen-sm-max){
            margin-bottom: 0;
            margin-left: $container-padding-x * -1;
            margin-right: $container-padding-x * -1;
            padding-left: $container-padding-x;
            padding-right: $container-padding-x;
            padding-bottom: $spacer * 2;

            .section-icon_icon-tease {
                text-align: center;
                position: relative;
                z-index: 0;
                // margin-top: -32px;
                margin-bottom: $spacer * 0.25;
                margin-top: $spacer * 0.25;
                &:before {
                    content: "";
                    position: absolute;
                    width: 70px;
                    height: 70px;
                    left: 50%;
                    top: -2px;
                    transform: translate3d(-50%,-50%,0);
                    background-color: $body-bg;
                    border-radius: 50%;
                    z-index: -1;
                }
                img {
                    max-width: 32px;
                    max-height: 32px;
                    margin-left: auto;
                    margin-right: auto;
                    transform: translate3d(0,-50%,0);
                }
            }
        }
        @media (min-width: $screen-md){
            background: transparent;
        }
    }
    .container > &_full,
    .container > &s_full {
        &-sm {
            @media (max-width: $screen-sm-max){
                margin-left: $container-padding-x * -1;
                margin-right: $container-padding-x * -1;
            }
        }
        &-md {
            @media (max-width: $screen-md-max){
                margin-left: $container-padding-x * -1;
                margin-right: $container-padding-x * -1;
            }
        }
    }
}