/* Forms */

.form-control {
  min-height: $input-height;
  color: $input-color;
  padding: $input-padding-y 0 $input-padding-y $input-padding-x;
  background-color: $input-bg;
  display: block;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  box-sizing: border-box;
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  &:disabled,
  &.disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
  }
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
  @media (min-width: $screen-md){
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    min-height: $input-height-sm;
  }
}

input[type=text], 
input[type=password], 
input[type=email], 
input[type=number],
input[type=search], 
textarea,
select {
  @extend .form-control;
}

input[type=search]{
  padding-right: 0;
  position: relative;
}

input[type=search]::-webkit-search-cancel-button {
  position: absolute;
  -webkit-appearance: none;
  background: transparent;
  background-image: escape-svg($form-search-close-bg-image);
  background-size: 12px;
  right: 6px;
  width: 12px;
  height: 12px;
}

input[type=text], 
input[type=password], 
input[type=email], 
input[type=number],
input[type=search], 
textarea {
  @include placeholder($input-placeholder-color){
    padding-right: 0;
  }
}

textarea {
    height: auto;
    min-height: $input-height;
    padding-right: $input-padding-x;
}
select {
  padding-right: $form-select-indicator-padding;
  background-image: escape-svg($form-select-indicator-bg-image);
  background-position: calc(100% - #{$input-padding-x * 0.75}) center;
  background-repeat: no-repeat;

  @media (min-width: $screen-md){
    padding-right: $form-select-indicator-padding-sm;
  }
}

.form-checkbox,
.form-radiobox {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  &_iconed {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > * {
      order: 1;
    }
    > svg,
    > .icon {
      order: 0;
    }
    span.icon {
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.form-checkbox input[type="checkbox"],
.form-radiobox input[type="radio"]{
    width: $form-check-input-width;
    height: $form-check-input-width;
    margin-top: ($line-height-base - $form-check-input-width) / 2; // line-height minus check height
    //vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $form-check-input-bg;
    background-size: $form-check-input-checked-bg-size;
    border: $form-check-input-border;
    appearance: none;
    display: block;
    flex: 0 0 auto;
    color-adjust: exact; // Keep themed appearance for print
    // @include transition($form-check-transition);

    &:active {
        filter: $form-check-input-active-filter;
    }

    &:focus {
        border-color: $form-check-input-focus-border;
        outline: 0;
        box-shadow: $form-check-input-focus-box-shadow;
    }
    .disabled,
    &[disabled],
    &:disabled {
        pointer-events: none;
        filter: none;
        opacity: .5;

        ~ .form-label,
        ~ .label,
        ~ label {
            opacity: .5;
        }
    }
    ~ .form-label,
    ~ .label,
    ~ label {
        margin-left: $spacer * 0.75;
        display: block;
        flex: 1 1 auto;
    }
}

.form-checkbox_iconed input[type="checkbox"],
.form-radiobox_iconed input[type="radio"] {
  ~ .form-label,
  ~ .label,
  ~ label {
    margin-top: $spacer * 0.125;
    line-height: 1.12;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  ~ svg,
  ~ .icon {
    fill: currentColor;
    color: $body-color;
  }
  &:checked {
    ~ svg,
    ~ label,
    ~ .icon,
    ~ .form-label,
    ~ .label {
      color: $primary;
    }
  }
  .form-group-item_grid & {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.form-checkbox_iconed,
.form-radiobox_iconed {
  position: relative;

  .form-group-item_grid & {
    border: 2px solid $white;
    margin-top: 0;
    margin-bottom: -2px;
    margin-right: -2px;
    padding: 10px 8px;

    .form-label,
    .label,
    label {
      font-size: $font-size-sm;
    }
  }
}

.form-checkbox input:checked[type="checkbox"],
.form-radiobox input:checked[type="radio"]{
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
}

.form-checkbox input {
  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
    // &:indeterminate {
    //     background-color: $form-check-input-indeterminate-bg-color;
    //     border-color: $form-check-input-indeterminate-border-color;

    //     @if $enable-gradients {
    //         background-image: escape-svg($form-check-input-indeterminate-bg-image), linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
    //     } @else {
    //         background-image: escape-svg($form-check-input-indeterminate-bg-image);
    //     }
    // }
  }
  &:checked {
    &[type="checkbox"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }
  }
}
.form-radiobox input {
  &[type="radio"] {
    // stylelint-disable-next-line property-blacklist
    border-radius: $form-check-radio-border-radius;
  }
  &:checked {
    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image), linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
      } @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }
}

.form-group {
  margin-bottom: $spacer;
  &:last-child {
    margin-bottom: 0;
  }
  &_space {
    &-md,
    &-md-top {
      margin-top: $spacer * 1.25;
    }
    &-md,
    &-md-bottom {
      margin-bottom: $spacer * 1.25;
    }
    &-md-top:last-child {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: $spacer;
    }
  }
  &-item {
    margin-bottom: $spacer * 0.75;
    &_search {
      display: flex;
      flex-direction: row;
      .form-search-input {
        display: block;
        flex: 1 1 auto;
        min-width: 80px;
        padding-right: 0;
        
        @media (min-width: $screen-md){
          margin-right: ($spacer * 0.625);
        }
      }
      select.form-search-input {
        margin-right: ($spacer * 0.625);
        padding-right: 26px;
        text-overflow: ellipsis;
      }
      .btn {
        flex: 0 0 auto;
      }
    }
    > .form-control:only-child {
      width: 100%;
    }
    &.row {
      align-items: center;
      min-width: 100%;
      @extend .gx-2;
    }
    &_sizes.row {
      @extend .gx-5;
      @extend .gx-sm-2;
      @extend .gx-md-5;
      @extend .gx-lg-2;
    }
    &_inline {
      margin-bottom: $spacer * 0.375;
    }
  }
  &,
  &-item {
    &_inline {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;

      > .form-radiobox {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
      > .form-radiobox,
      > div {
        margin-bottom: $spacer * 0.375;
      }
    }
  }
  &-item &_inline {
    margin-bottom: $spacer * -0.375;
  }
  &-item.row {
    input {
      max-width: 100%;
    }
    select {
      display: block;
      width: 100%;
    }
    .form-control:only-child {
      width: 100%;
    }
  }
  &-item.row &-label,
  &-item.row &-field {
    display: block;
  }
  &-item.row &-field_stretch .form-control {
    width: 100%;
  }
  &-item_grid,
  &_grid {
    min-width: 100%;
  }
  &-item_grid {
    @extend .block-row;
  }
  &-item_grid > div:not([class*="col-"]),
  &_grid &-item {
    @extend .col-4;
    @extend .col-md-3;
  }
  &_full {
    min-width: 100%;
    @media (max-width: $screen-sm-max){
      .block_form > & {
        margin-left: $spacer * -1;
        margin-right: $spacer * -1;

        &:only-child {
          margin: $spacer * -1;
          overflow: hidden;
        }
        &:first-child {
          margin-top: $spacer * -1;
        }
        &:only-child,
        &:first-child {
          > .form-group-item_grid:first-child {
            margin-top: -2px;
          }
        }
      }
    }
  }
}

.form-group-label,
.form-item-label {
  padding-top: $spacer * 0.25;
  padding-bottom: $spacer * 0.25;
}

.form-captcha {
  border: 1px solid #808285;
}

img.form-captcha {
  max-width: 100%;
  width: 100%;
  display: block;
  height: 44px;
  @media (min-width: $screen-md) {
    height: 34px;
  }
}

.form-checkbox {
  display: flex;
  
  &.mandatory {
    label {
    &:after {
        content: "*";
        display: inline;
        margin-left: $spacer * 0.125;
      }
    }
  }
}

.form-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacer;
  margin-bottom: $spacer;

  &_left,
  &_right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
  }
  &_left {
    justify-content: flex-start;
  }
  &_right {
    justify-content: flex-end;
  }
  &,
  &_left,
  &_right {
    .btn {
      margin-right: $spacer * 0.625;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_left,
  &_right {
    flex: 1 1 auto;
    @media (max-width: $screen-xs-max){
      width: 100%;
      .btn:only-child {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.form-error {
  &s {
    margin-top: $spacer * 0.5;
    margin-bottom: $spacer * 1.5;
    color: $danger;
    padding-left: 28px;
    position: relative;
  }
  &-notice {
    display: block;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      left: 0;
      margin-top: 2px;
      background: url(../images/icons/warning.svg) center no-repeat;
      background-size: 20px auto;
    }
  }
  &-list {
    font-size: 12px;
    margin-top: 6px;
    li {
      font-size: 13px;
    }
  }
  &.form-group-item {
    .form-group-label {
      color: $danger;
    }
    select,
    .form-control {
      &,
      &:focus {
        border-color: $danger;
      }
    }
    .form-radiobox input[type="radio"],
    .form-checkbox input[type="checkbox"]{
      border-color: $danger;
    }
  }
}
.form-control.form-error,
select.form-error,
.form-control.form-error:focus,
select.form-error:focus,
.form-checkbox.form-error input[type="checkbox"],
.form-radiobox.form-error input[type="radio"] {
  border-color: $danger;
}

@media (min-width: $screen-md){
  .form-group-item_grid {
    margin-top: -32px;
    margin-bottom: -24px;
    overflow: hidden;
    position: relative;
    width: auto;

    .form-checkbox_iconed,
    .form-radiobox_iconed {
       padding-top: 32px;
       padding-bottom: 32px;
       margin: 0;
       border: none;
       position: static;
       justify-content: flex-start;
       border: none;

       &:before {
        content: "";
        border-top: $footer-hr-border-top;
        border-bottom: $footer-hr-border-bottom;
        display: block;
        height: 2px;
        margin-top: -34px;
        position: absolute;
        left:  $container-padding-x;
        right: $container-padding-x;
        width: auto;
        order: 0;
        justify-self: start;
       }
       .form-label,
       .label,
       label {
         font-size: $font-size-base;
       }
    }
  }
  .form-checkbox_iconed input[type="checkbox"],
  .form-radiobox_iconed input[type="radio"] {
    .form-group-item_grid & {
      width: $form-check-input-width;
      height: $form-check-input-width;
      opacity: 1;
      position: static;
    }
    ~ .form-label,
    ~ .label,
    ~ label {
      margin-top: $spacer * 0.5;
    }
  }
  .nowrap-md {
    flex-wrap: nowrap;
  }
}