.table {
    position: relative;
    border-spacing: 0;

    &_full {
        width: 100%;
    }
    &_fixed {
        table-layout: fixed;
    }

    .text-delimiter,
    .spacer-cell {
        display: none;
    }
    &-wrapper {
        max-width: 100%;
        display: block;
        overflow: auto;
        position: relative;
    }
    &-wrapper & {
        position: static;
    }

    thead,
    &-header {
        background-color: $table-header-bg;
        color: $table-header-color;
        border: $table-border-width solid $table-header-bg;
        border-width: 0 $table-border-width;
        width: auto;

        .icon {
            max-height: 28px;
            max-width: 28px;
            min-width: 20px;
        }
        th,
        .table-header-cell {
            &.table-cell-sticky.sticky-right {
                border-right-width: 0;
                .table-cell-bg {
                    background-color: $table-header-bg;
                    right: -1px;
                    border-right-width: 0;
                }
            }
            &.table-cell-sticky.sticky-left {
                border-left-width: 0;
                .table-cell-bg {
                    background-color: $table-header-bg;
                    left: -1px;
                    border-left-width: 0;
                }
            }
        }
        .table-wrapper.sticky-right & {
            border-right-width: 0;
        }
    }
    thead.striped,
    &-header.table-header_striped {
        @extend .bg_striped;
        border-color: $table-border-color;
        color: $table-header-striped-color;
        border-top-width: $table-border-width;
        border-top-style: solid;

        th,
        .table-header-cell {
            &.table-cell-sticky.sticky-left,
            &.table-cell-sticky.sticky-right {
                .table-cell-bg {
                    @extend .bg_striped;
                }
            }
        }
    }
    thead th,
    &-header-cell {
        border-left: $table-border-width solid $table-header-border-color;
        padding: $table-cell-padding;
        vertical-align: middle;
        height: 44px;
        &:first-child,
        &:first-of-type {
            border-left-width: 0;
        }
    }
    thead.striped th,
    &-header.table-header_striped &-header-cell {
        border-color: $table-border-color;
    }
    tbody th,
    td,
    &-cell {
        padding: 0;
        border-left: $table-border-width solid $table-border-color;

        &:first-of-type {
            border-left-width: 0;
        }
        .contents {
            padding: $table-cell-padding;
            display: block;
            width: 100%;
        }
    }
    th,
    &-cell-sticky {
        &.sticky-left {
            position: sticky;
            left: 0;
        }
        &.sticky-right {
            position: sticky;
            right: 0;
            .table-cell-bg {
                width: calc(100% + #{$table-border-width});
            }
        }
        .table-cell-bg {
            display: block;
            width: calc(100% + #{$table-border-width * 2});
        }
    }
    &-cell-bg {
        position: absolute;
        top: 0;
        left: -1px;
        z-index: -1;
        border-left: $table-border-width solid $table-border-color;
        border-right: $table-border-width solid $table-border-color;
        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: inherit;
        }
        &:before {
            background-color: $body-bg;
        }
    }
    .table tbody &-cell-heading {
        padding: $table-cell-padding;
        display: none;
    }
    &-check {
        @extend .form-checkbox;
    }
    &-cell-check {
        width: 44px;
    }
    tbody tr,
    &-row {
        position: relative;
        &,
        .table-cell-bg{
        background-color: $body-bg;
        }
        .cell-contents {
            border-top: $table-border-width solid $table-border-color;
            margin-top: -1px;
            width: 100%;
        }
        &:nth-child(odd){
            &,
            .table-cell-bg{
            background-color: $table-striped-bg;
            }
        }
        &:first-child {
            .cell-contents {
                border-top-width: 0;
            }
        }
    }
    tbody td,
    &-row &-cell {
        vertical-align: top;
    }
    > tr,
    & > &-row {
        &:first-child {
            border-top-width: $table-border-width;
        }
    }
    tbody,
    &-body {
        border: $table-border-width solid $table-border-color;
        border-width: 0 $table-border-width;
        background-color: $body-bg;

        .table-wrapper.sticky-right & {
            border-right-width: 0;
        }
        // .table-wrapper.sticky-left & {
        //     border-left-width: 0;
        // }
        
        &:last-child {
            border-bottom-width: $table-border-width;
        }
        &:first-child {
            .table-cell,
            td, th {
                border-top-width: $table-border-width;
            }
        }
        &.collapsible {
            // transition: transform 0.4s ease-in-out;
            .cell-contents {
                overflow: hidden;
                max-height: 120px;
                transition: max-height 0.25s ease-in-out;
            }
            th, td, .cell-contents, .contents {
                transition-delay: inherit;
            }
        }
    }
    tbody + tbody,
    &-body + &-body {
        tr:first-child,
        .table-row:first-child {
            .cell-contents {
                border-top-width: $table-border-width;
            }
        }
    }
    &-body_even {
        tr,
        .table-row {
            &:nth-child(even){
                @if $table-striped-order == 'odd' {
                    &,
                    .table-cell-bg{
                    background-color: $table-striped-bg;
                    }
                } @else {
                    &,
                    .table-cell-bg{
                    background-color: $body-bg;
                    }
                }
            }
            &:nth-child(odd){
                @if $table-striped-order == 'odd' {
                    &,
                    .table-cell-bg{
                    background-color: $body-bg;
                    }
                } @else {
                    &,
                    .table-cell-bg{
                    background-color: $table-striped-bg;
                    }
                }
            }
        }
    }
    &.collapsed tbody.collapsible,
    &.collapsed &-body.collapsible {
        .cell-contents {
            max-height: 0 !important;
            border-top-color: transparent;
            transition: max-height 0.25s ease-in-out, border-top-color 0ms 0.25s ease-in-out;
        }
    }
    &-footer {
        &-control {
            td {
                padding: 0;
                border-top-width: 0;
                text-align: center;
            }
            .btn_toggle-collapse {
                transform: translate3d(0, -50%, 0);
            }
        }
    }
    .btn_toggle-collapse:before {
        height: 1px;
        top: 50%;
        opacity: 0;
    }
    &.collapsed .btn_toggle-collapse:before {
        opacity: 1;
    }
    &-actions {
        display: flex;
    }
    &-action {
        appearance: none;
        border: none;
        outline: none;
        position: relative;
        display: block;
        background: transparent;

        &:active,
        &:focus {
            outline: none;
            border: none;
            box-shadow: none;
        }

        &.icon {
            width: 32px;
            height: 44px;
            min-height: 44px;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            @include icon-glyph('plus') {
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 40px;
                transform: translate3d(-50%,-50%,0);
            }
            &_edit:before {
                @include icon-glyph('tab-edit', false);
            }
            &_remove:before {
                @include icon-glyph('tab-delete', false);
            }
            &_send:before {
                @include icon-glyph('tab-startorder', false);
            }
            &_more:before {
                @include icon-glyph('tab-more', false);
            }
            @media (min-width: $screen-md){
                margin: 0;
                min-height: auto;
                height: 32px;

                &:before {
                    font-size: 28px;
                }
            }
        }
    }
    &_mobile-list {
        @media (max-width: $screen-sm-max){
            thead th,
            .table-header-cell {
                display: none;
            }
            .table-header,
            thead tr,
            thead {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            thead .visible-xs,
            thead .table-cell-check {
                display: block;
            }
            tbody td,
            tbody th,
            .table-cell,
            tbody .table-cell-heading {
                display: block;
                border-left: none;

                .cell-contents {
                    border-top-width: 0;
                }
            }
            tbody td,
            .table-cell {
                width: 60%;
                padding-top: 0;

                &.hidden-xs {
                    display: none;
                }
                .contents {
                    padding-top: 0;
                }
            }
            tbody .table-cell-check,
            tbody th {
                width: 100%;
            }
            tbody .table-cell-heading {
                max-width: 33%;
                padding-top: 0;
                flex: 0 1 auto;
            }
            tbody tr {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    top: 0;
                    border-top: $table-border-width solid $table-border-color;
                }
            }
        }
    }
    &_mobile-overview {
        @media (max-width: $screen-sm-max + $table-mobile-overview-bp-delay){
            &.table_fixed {
                table-layout: auto;
                colgroup {
                    display: none;
                }
            }
            thead th,
            .table-header-cell {
                display: none;
            }
            thead tr,
            thead {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            thead .visible-xs {
                display: block;
            }
            th,
            .table-cell-sticky {
                .table-cell-bg {
                    display: none;
                }
                position: static;
            }
            tbody td,
            tbody th,
            .table-cell,
            tbody .table-cell-heading {
                display: block;
                border-left: none;

                .cell-contents {
                    border-top-width: 0;
                }
            }
            tbody {
                border-left-width: 0;
                border-right-width: 0;
            }
            tbody td,
            .table-cell {
                width: auto;
                padding-top: 0;
                order: 0;
                
                &.visible-expanded,
                &.hidden-xs {
                    display: none;
                }
                .contents {
                    padding-top: 0;
                    padding-left: 0;
                    padding-bottom: .25rem;
                }
                &.order-xs-1 {
                    order: 1;
                }
                &.inline-xs {
                    &,
                    &-flex {
                        width: auto;
                        .contents {
                            padding-right: .25rem;
                        }
                    }
                    &-flex {
                        flex: 1;
                        display: flex;
                        max-width: 100%;
                        width: 100%;

                        &:after {
                            content: "";
                            display: block;
                            width: 100%;
                        }
                    }
                }
                &.strong-xs {
                    font-weight: $font-weight-bold;
                }
                &.excerpt {
                    font-size: 0;
                    line-height: 0;
                    .contents div,
                    br {
                        display: none;
                    }
                    .excerpt {
                        font-size: $font-size-base;
                        line-height: 1.5;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                    .text-delimiter {
                        display: inline;
                    }
                }
            }
            tr:not(.active) {
                td,
                .tabel-cell {
                    .contents .table-actions:first-child {
                        display: block;
                        position: absolute;
                        top: 50%;
                        width: 32px;
                        height: 32px;
                        right: 8px;
                        transform: translate3d(0,-50%,0);
                        text-align: center;
                        @include icon-glyph('tab-more');
                        &:before {
                            font-size: 28px;
                            line-height: 32px;
                        }
                        > * {
                            display: none;
                        }
                    }
                }
            }

            tbody {
                .spacer-cell {
                    display: block;
                    width: 100%;
                }
                .table-cell-check {
                    display: none;
                }
                tr.error,
                .table-row.error {
                    padding-left: $spacer * 2.5;
                    color: $danger;
                    overflow: hidden;
                    
                    .table-cell-check {
                        display: block;
                        position: absolute;
                        left: $spacer;
                    }
                    .contents_error {
                        width: 1.625rem;
                        margin-left: -0.125rem;
                        margin-top: -0.125rem;
                    }
                }
                .table-cell-check,
                th {
                    width: 100%;
                }
                .table-cell-heading {
                    max-width: 30%;
                    padding-top: 0;
                    flex: 0 1 auto;
                }
                tr {
                    padding-left: $spacer;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: stretch;
                    position: relative;
                    padding-top: 0.625rem;
                    padding-bottom: 0.5rem;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        left: 0;
                        top: 0;
                        border-top: $table-border-width solid $table-border-color;
                    }
                }
            }
            tbody tr,
            .table-row {
                &.toggle-row {
                    &.active {
                        color: $primary;
                        padding-right: 32px;
                        min-height: 150px;

                        td,
                        .table-cell {
                            &.excerpt {
                                font-size: $font-size-base;
                                line-height: 1.5;
                                .contents div {
                                    display: block;
                                }
                                .contents .table-actions {
                                    padding-top: 10px;
                                    display: flex;
                                    padding-bottom: 10px;
                                }
                                .table-action.icon {
                                    color: inherit;
                                }
                            }
                        }
                        .contents .table-actions {
                            flex-direction: column;
                            position: absolute;
                            right: 8px;
                            top: 0;
                            bottom: 0;
                            justify-content: space-between;
                            align-items: center;
                        }
                        &.error {
                            color: $danger;
                            .table-actions {
                                color: $primary;
                            }
                        }
                    }
                }
            }
            &.checkable {
                tbody .table-cell-check {
                    display: block;
                    position: absolute;
                    right: 0;
                    width: auto;
                    left: auto;
                }
                tbody tr,
                .table-row {
                    &.toggle-row.active {
                        .table-cell.excerpt .contents > .table-actions {
                            display: none;
                        }
                    }
                }
            }
        }
        @media (min-width: $screen-s) and (max-width: $screen-sm-max + $table-mobile-overview-bp-delay){
            tbody td,
            .table-cell {
                &.excerpt .excerpt {
                    white-space: nowrap;
                }
            }
        }
        @media (min-width: $screen-md + $table-mobile-overview-bp-delay){
            tbody th,
            tbody td,
            .table-cell {
                &.hidden-md {
                    display: none;
                }
            }
            .text-delimiter {
                display: none;
            }
            &.table_fixed .table-actions {
                flex-wrap: wrap;
            }
        }
        @media (min-width: $screen-md + $table-mobile-overview-bp-delay) and (max-width: $screen-lg-max){
            .table-cell {
                &_medium {
                    width: 10%;
                }
                &_large {
                    width: 18%;
                }
            }
            .table-actions {
                flex-direction: column;
            }
        }
    }
    @media (min-width: $screen-md + $table-mobile-overview-bp-delay) and (max-width: $screen-lg-max){
        &_mobile-overview {
            .table-actions {
                flex-direction: column;
            }
            .table-cell {
                &_m {
                    width: 10%;
                }
                &_l {
                    width: 18%;
                }
            }
        }
    }
    
    @media (max-width: $screen-sm-max + $table-mobile-overview-bp-delay){
        &_mobile-overview {
            width: 100%;
        }
    }
    @media (max-width: $screen-sm-max){
        &_full-sm {
            width: 100%;
        }
    }
}