@import '../_vendor/variables';
@import '_variables-color';
@import '_variables-font-icons';

$font-name-base: 'Iloxx-Font';
$font-name-icons: 'Iloxx-Icon-Font';
$font-family-sans-serif:      #{$font-name-base}, 'EnzoOffc', Helvetica, Arial, sans-serif;//system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif;
$line-height-base: 1.4 !default;

$font-size-s: $font-size-base * 0.8125;

$h1-font-size:                $font-size-base * 1.875 !default; // 30px
$h2-font-size:                $font-size-base * 1.5 !default; // 24px
$h3-font-size:                $font-size-base * 1.5 !default; // 24px
$h3-font-size-mobile:         $font-size-base * 1.375 !default; // 22px
$h4-font-size:                $font-size-base * 1.25 !default; // 20px
$h5-font-size:                $font-size-base * 1.25 !default; // 20px;
$h6-font-size:                $font-size-base !default;

$_spacers: (
  0: 0,
  1: $spacer * 0.3125,
  2: $spacer * 0.625,
  3: $spacer,
  4: $spacer * 1.25,
  5: $spacer * 1.5,
  6: $spacer * 2,
  7: $spacer * 3
) !default;

$spacers : $_spacers;
$gutters : $_spacers;


$body-color: $gray;
$mark-bg: rgba($beige, .25);

$striped-bg-image: url('data:image/gif;base64,R0lGODlhBQAFAIAAAP///+zs7CH5BAAAAAAALAAAAAAFAAUAAAIHhBGXtqgHCgA7');

$input-color: $gray-dark;
$input-bg: $white;
$input-disabled-bg: $white-soft;
$input-disabled-color: rgba($input-color,.5);
$input-border-radius: 0;
$input-btn-focus-width: 1px;
$input-btn-focus-box-shadow: 0 0 1px $input-btn-focus-width $input-btn-focus-color;
$input-focus-box-shadow: $input-btn-focus-box-shadow;
$input-placeholder-color: rgba($input-color,.5);


$form-check-input-border: $input-border-width solid $input-border-color;
$form-check-input-width: 1.5rem;
$form-check-input-border-radius: 0;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color:          $primary;
$form-check-input-checked-bg-color:       $input-bg;
$form-check-input-checked-bg-size:        auto 15px;
$form-check-input-checked-border-color:   $input-border-color;
$form-check-input-checked-bg-image:       url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path fill="#{$form-check-input-checked-color}" d="M18.798 58.004c-1.714 0-3-.856-3.856-2.572l-7.287-15c-1.285-2.144-.429-4.714 1.715-6 2.143-.856 4.713 0 5.572 2.144l4.285 8.144 25.714-40.715c1.285-2.143 3.855-2.571 6-1.286 2.143 1.286 2.57 3.856 1.285 6l-30 47.144c-.428 1.284-1.714 2.141-3.428 2.141z"/></svg>');
$form-check-radio-checked-bg-image:       url('data:image/svg+xml,<svg version="1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path stroke="#{$form-check-input-checked-color}" stroke-width="2.6" stroke-linecap="round" stroke-miterlimit="10" d="M2.5 2.457l15 15.086m-15 0l15-15.086" fill="none"/></svg>');

$form-search-close-color: $primary;
$form-search-close-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path stroke="#{$form-search-close-color}" stroke-width="1.5" stroke-linecap="round" stroke-miterlimit="10" d="M2 1.965l12 12.07m-12 0l12-12.07" fill="none"/></svg>');

$code-color: $primary;

$input-btn-padding-y: .5625rem; //used as default size (mobile)
$input-btn-padding-x: .5rem;

$input-btn-padding-y-sm:      .3125rem !default; //used for smaller size on desktop
$input-btn-padding-x-sm:      .5rem !default; 

$input-btn-padding-y-lg:      .5rem; 
$input-btn-padding-x-lg:      1rem;

$form-select-indicator-padding: add($input-padding-x, 1.5rem);
$form-select-indicator-padding-sm: add($input-btn-padding-x-sm, 1.5rem);
$form-select-indicator-color: $gray-medium;
$form-select-indicator-bg-image: url('data:image/svg+xml,<svg version="1" xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12"><path fill="#{$form-select-indicator-color}" d="M.9 1.8l9.1 6.5 9.1-6.5v3.5l-9.1 6.5-9.1-6.5v-3.5z"/></svg>');

$btn-padding-y: $input-btn-padding-y;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-y-lg: $input-btn-padding-y-lg;

$input-height:       add(add($input-line-height * 1rem, $input-btn-padding-y * 2), $input-height-border);
$input-height-sm:    add(add($input-line-height * 1rem, $input-padding-y-sm * 2), $input-height-border);
$input-height-lg:    add(add($input-line-height * 1rem, $input-padding-y-lg * 2), $input-height-border);

$container-padding-x: $spacer * 0.625;
$grid-gutter-width: $spacer * 1.25;

$alert-padding-x: $container-padding-x;
$alert-padding-y: $spacer * 0.375;
$alert-info-bg-color: $gray-beige2;
$alert-info-color: $gray-dark;
$alert-close-color: $primary;
$alert-warning-bg-color: rgba($warning,.15);
$alert-warning-color: $gray-dark;
$alert-error-bg-color: rgba($danger,.15);
$alert-error-color: $danger;
$alert-success-bg-color: rgba($success,.15);
$alert-success-color: $success;
$alert-highlight-border: 1px solid $primary;
$alert-note-md-padding: ($spacer * 0.875) ($alert-padding-x * 2);

$btn-color: $white;
$btn-bg: $primary;
$btn-secondary-bg: $beige-dark;
$btn-hover-color: $white;
$btn-hover-bg: $white-soft;
$btn-border-color: transparent;
$btn-bordered-border-color: $white;
$btn-font-weight: $font-weight-bold;
$btn-md-padding-x: 0.875rem;
$btn-toggleCollapse-size: 30px;
$btn-toggleCollapse-color: $gray-light; // color for the line left & right
$btn-toggleCollapse-bg-color: $body-bg;
$btn-toggleCollapse-box-shadow: 0 0 2px $btn-toggleCollapse-bg-color;
$btn-toggleCollapse-collapse-box-shadow: 0 -10px 30px 15px $btn-toggleCollapse-bg-color;

$btn-rounded-border-radius: 8px;
$btn-rounded-border-md-radius: 6px;
$btn-round-border-radius: $font-size-base * 1.375;
$btn-round-white-space: nowrap;

// /*------------------------------------*\
// 	#BREAKPOINTS
// \*------------------------------------*/

$screen-s: 448px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;

$screen-xs-max: $screen-s - 1px;
$screen-s-max: $screen-sm - 1px;
$screen-sm-max: $screen-md - 1px;
$screen-md-max: $screen-lg - 1px;
$screen-lg-max: $screen-xl - 1px;
$screen-xl-max: $screen-xxl - 1px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$_grid-breakpoints: (
  xs: 0,
  s: $screen-s,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
) !default;
$grid-breakpoints: $_grid-breakpoints;
// scss-docs-end grid-breakpoints

@include _assert-ascending($_grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($_grid-breakpoints, "$grid-breakpoints");

$_container-max-widths: (
  sm: 740px,
  md: 920px,
  lg: 980px,
  xl: 1240px,
  xxl: 1300px
) !default;

$container-max-widths: $_container-max-widths;
// scss-docs-end container-max-widths

@include _assert-ascending($_container-max-widths, "$container-max-widths");

$menu-btn-bg-color: $gray-beige2;
$menu-btn-color: $gray-dark;

$footer-element-font-size: $font-size-sm;
$footer-element-color: $gray;
$footer-hr-border-top: 1px solid $gray-beige-medium;
$footer-hr-border-bottom: 1px solid $white-soft;
$footer-hr-margin: $spacer 0;
$footer-bg-color: $gray-beige;
$footer-padding: ($spacer * 0.625) 0 60px;
$footer-padding-md: ($spacer * 0.625) 0;

$header-container-bg-color: $white;

$header-nav-max-height: calc(100vh - 100px);
$header-nav-bg-color: $gray-beige2;
$header-nav-color: #aaa;//$gray-dark;
$header-nav-hover-color: $primary;
$header-nav-current-color: $gray-dark;
$header-nav-active-color: $primary;
$header-nav-current-hover-color: $primary;
$header-subnav-border-top: $footer-hr-border-top;
$header-subnav-border-bottom: $footer-hr-border-bottom;
$header-nav-bottom-sticky-bg-color: $gray-dark;
$header-nav-bottom-sticky-color: $white;
$header-nav-passive-enabled: false;

$header-md-nav-bg-color: $primary;
$header-md-nav-color: $white;
$header-md-nav-active-bg-color: $gray-beige;
$header-md-nav-active-color: $gray-dark;
$header-md-subnav-color: $gray-dark;
$header-md-subnav-bg-color: $header-md-nav-active-bg-color;
$header-dropdown-width: 320px;

$nav-dropdown-bg-color: $header-md-subnav-bg-color;
$nav-dropdown-color: $header-md-subnav-color;
$nav-dropdown-padding: ($spacer * 0.625);
$nav-dropdown-min-width: 280px;

$banner-dot-size: 12px;
$banner-dot-border-width: 2px;
$banner-dot-color: $gray-dark;
$banner-dot-light-color: $white;
$banner-inner-max-width: map-get($_container-max-widths, 'xxl');

$banner-slides-min-height: 176px;
$banner-slides-s-min-height: 200px;
$banner-slides-sm-min-height: 220px;
$banner-slides-md-min-height: 240px;
$banner-slides-lg-min-height: 280px;

$steplist-numeration-bg-color: $gray-light;
$steplist-numeration-color: $primary;
$list-feature-prefix-color: $primary;

$modal-dialog-margin: $container-padding-x;
$modal-backdrop-bg: $gray-dark;
$modal-backdrop-opacity: .75;
$modal-content-border-radius: false;
$modal-content-inner-border-radius: false;
$modal-content-box-shadow-xs: false;
$modal-header-bg: transparent;
$modal-header-extend-btn: true;
$modal-content-border-width: 0;
$modal-header-padding: 0;
$modal-title-line-height: 1.2;
$modal-title-font-size: $font-size-base;

$link-list-item-color: $primary;
$link-list-item-margin-x: $spacer;
$link-list-title-line-height: 1.25;
$link-list-title-height: 2.4 * $font-size-base; // for 2 lines visible by default
$link-list-icons-item-margin: 0 ($spacer * 0.75) ($spacer / 3);
$link-list-light-link-color: $body-color;
$link-list-delimited-item-margin-bottom: $spacer * 1.5;
$link-list-delimited-item-padding-top: $spacer * 0.25;
$link-list-delimited-item-delimiter-margin: $spacer * -1.125 0 0;
$link-list-delimited-item-delimiter-spacing-x: $grid-gutter-width * 0.5 + $container-padding-x;
$link-list-delimited-item-delimiter-md-spacing-x: $grid-gutter-width * 0.5 + $spacer * 1.25;
$link-list-delimited-item-delimiter-color: $gray-light;
$link-list-delimited-item-delimiter-background: transparent no-repeat left center;
$link-list-delimiter-item-delimiter-background-image: linear-gradient(to right, rgba(255,255,255,0) 0%, currentColor 100%), linear-gradient(to right, currentColor 0%, currentColor 100%), linear-gradient(to right, currentColor 0%, rgba(255,255,255,0) 100%);
$link-list-delimited-item-delimiter-gradient-size: 80px;
$link-list-delimited-item-delimiter-height: 1px;
$link-list-delimited-margin-bottom: $link-list-delimited-item-margin-bottom * -0.5;

$card-list-card-border-color: $gray-beige3;
$card-list-card-border-width: 1px;
$card-list-card-image-max-width: 120px;

$tab-color: $gray-dark;
$tab-opacity: 0.5;
$tab-active-color: $primary;
$tab-active-opacity: 1;
$tab-hover-opacity: 0.8;
$tab-padding: ($spacer * 0.625);
$tab-md-padding: ($spacer * 0.75) ($spacer * 1.25);
$tab-active-bg: false;
$tab-md-active-bg: linear-gradient(to top, $white-medium 30%, rgba(255,255,255,0) 100%);

$tab-bar-item-bg: $gray-light;
$tab-bar-item-color: $body-color;
$tab-bar-item-active-bg: $primary;
$tab-bar-item-active-color: $white;


$table-cell-padding:          .625rem;
$table-cell-padding-sm:       .25rem !default;
$table-cell-vertical-align:   top !default;
$table-color:                 $body-color !default;
$table-bg:                    transparent !default;
$table-striped-color:         $table-color !default;
$table-striped-bg-factor:      .05 !default;
$table-striped-bg:            $gray-beige !default;
$table-active-color:          $table-color !default;
$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($black, $table-active-bg-factor) !default;
$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba($black, $table-hover-bg-factor) !default;
$table-border-factor:         .1 !default;
$table-border-width:          $border-width !default;
$table-border-color:          $border-color !default;
$table-striped-order:         'odd';
// $table-group-seperator-color: currentColor !default;
$table-caption-color:         $text-muted !default;

// $table-bg-level:              -9 !default;

$table-header-color: $white;
$table-header-bg: $beige-dark;
$table-header-border-color: $white;
$table-header-striped-color: $primary;
$table-header-striped-bg: $white;

$table-mobile-overview-bp-delay: 150px;