.nav-elementBorder {
    display: none;
    position: absolute;
    transition: transform 0.3s ease-in-out, width 0.2s ease-in-out, left 0.3s ease-in-out;
    height: 1px;
    border: 1px solid $tab-active-color;
}
.nav-tab {
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
    color: $tab-color;
    opacity: $tab-opacity;
    border-bottom: 1px solid transparent;
    &s {
        text-align: center;
        display: block;
        width: 100%;
        &_full {
            text-align: center;
            display: block;
            width: calc(100% + #{$spacer * 1.25});
            margin-left: $spacer * -0.625;
            margin-right: $spacer * -0.625;
            &:after {
                content: "";
                display: table;
                height: 0;
                clear: both;
            }
        }
    }
    &s_full &-contents {
        margin-left: $spacer * 0.625;
        margin-right: $spacer * 0.625;

        .nav-tab-contents {
            margin-right: 0;
            margin-left: 0;
        }
    }

    &:before {
        content: normal;
    }
    &-container {
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        justify-content: center;
        max-width: 100%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        position: relative;
        &:before {
            content: "";
            bottom: 0;
            position: absolute;
            z-index: 2;
            left: ($link-list-delimited-item-delimiter-gradient-size + 50) * -1;
            right: ($link-list-delimited-item-delimiter-gradient-size + 50) * -1;
            @include delimiterBorder();
        }
        &_slider {
            &.slider-enabled {
                display: flex;
                overflow: hidden;
                white-space: nowrap;
                justify-content: flex-start;
                &:before {
                    content: "default";
                }
                .nav-tab {
                    white-space: normal;
                    flex: none;
                    height: 100%;
                    width: auto;
                //    position: absolute;
                    &.active {
                        border-bottom-color: transparent;
                    }
                }
                .nav-elementBorder {
                    display: block;
                    bottom: 0;
                    z-index: 2;
                }
                &:after {
                    content: "";
                    right: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    display: block;
                    height: auto;
                    box-shadow: -10px 0 48px 32px $white, -5px 0 20px 10px $white;
                    width: 1px;
                    background-color: $white;
                    z-index: 1;
                }
            }
            .nav-tab {
                flex: 0 0 auto;
            }
        }
    }
    .nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        overflow: hidden;
        padding: $tab-padding;

        span {
            display: block;
            max-width: 100%;
            // overflow: hidden;
            // -webkit-line-clamp: 2;
            // text-overflow: ellipsis;
            // white-space: nowrap;
        }
        img {
            max-width: 100%;
            height: auto;
            width: auto;
            display: block;
        }
        .link-text {
            color: $tab-color;
            font-size: $font-size-sm;
            margin-top: $spacer * -0.25;
            text-align: center;
        }
        .link-title {
            font-weight: $font-weight-bold;
            margin-top: $spacer * -0.125;
            text-align: center;
            line-height: 1.25;
        }
    }
    .link-icon {
        svg {
            fill: currentColor;
        }
    }
    &:hover {
        opacity: $tab-hover-opacity;
        cursor: pointer;
    }
    &.active {
        @if $tab-active-bg {
        background: $tab-active-bg;
        }
        &,
        &:hover {
            color: $tab-active-color;
            opacity: $tab-active-opacity;
            border-bottom-color: $tab-active-color;
            cursor: default;
        }
    }
    &-contents {
        text-align: left;
        > div {
            display: none;
            &.active {
                display: block;
            }
        }
        &_medium {
            margin-top: $spacer * 0.75;
            margin-bottom: $spacer * 1.25;
        }
        &_slim {
            margin-top: $spacer * 0.625;
            margin-bottom: $spacer * 1.125;
        }
        &_large {
            margin-top: $spacer * 2.5;
            margin-bottom: $spacer * 4;
        }
    }
    &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &-enum {
            font-family: $font-name-base;
            display: block;
            transform: none;
            box-sizing: border-box;
            position: static;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            box-sizing: border-box;
            line-height: 52px;
            font-size: 35px;
            color: $tab-bar-item-active-color;
            background-color: $tab-bar-item-active-bg;
            text-align: center;
            display: block;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: auto;
            margin-right: auto;
        }
        &-title {
            padding-top: $spacer * 0.5;
            color: $body-color;
            font-weight: $font-weight-bold;
            text-align: center;
            width: auto;
            background: transparent;
        }
    }
    @media (min-width: $screen-md){
        flex: 0 1 auto;

        &-container {
            display: inline-flex;
            width: auto;
        }
        &-contents {
            &_medium {
                margin-top: $spacer * 1.5;
                margin-bottom: $spacer * 2.25;
            }
            &_slim {
                margin-top: $spacer * 1;
                margin-bottom: $spacer * 1.25;
            }
            &_large {
                margin-top: $spacer * 3;
                margin-bottom: $spacer * 5;
            }
        }
        .nav-link {
            padding: $tab-md-padding;
        }
        &s_md-text & {
            .link-icon {
                display: none;
            }
        }
        @if $tab-md-active-bg {
            &.active {
                background: $tab-md-active-bg;
            }
        }
    }
    
}