.nav-dropdown {
    display: none;
    background-color: $nav-dropdown-bg-color;
    color: $nav-dropdown-color;
    padding: $nav-dropdown-padding;
    min-width: $nav-dropdown-min-width;
    position: absolute;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    
    .form {
        &-control {
            width: 100%;
        }
        &-checkbox {
            font-size: $font-size-sm;
            flex: 1 1 auto;
            
            label {
                padding-top: 2px;
            }
        }
        &-group-item {
            display: flex;
            align-items:center;
        }
    }
    .btn {
        display: block;
    }
    p.text-heading_dotted {
        margin-bottom: 2px;
    }
    .nav-list-item & {
        .link {
            @extend .link;
            font-size: $font-size-sm;
        }
    }
}