.container_large {
    @extend .container-xl;
    @media (max-width: $screen-lg-max){
        padding-left: 0;
        padding-right: 0;
    }
}
.container_medium {
    @extend .container-md;
    @media (max-width: $screen-md-max){
        padding-left: 0;
        padding-right: 0;
    }
}
.container-inner {
    overflow: hidden;
}
@media(min-width: $screen-lg){
    .container-inner {
        max-width: 940px;
    }
}

main {
    margin-top: 44px;
    
    @media(min-width: $screen-md){
        margin-top: 0;
    }
}

.no-wrap {
    flex-wrap: nowrap;
}
.col-md_flex {
    @media(min-width: $screen-md) and (max-width: $screen-md-max){
        flex: 1;
    }
}