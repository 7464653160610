.modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    right: 0;
    bottom: 0;
    overflow: hidden;
    outline: 0;
    &:before {
        content: "";
        background-color: $modal-backdrop-bg;
        position: absolute;
        z-index: -1;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: opacity 0.25s ease-in;
    }

    // &-open {
    //     overflow: hidden; // Kill the scroll on the body (is added/removed from body-tag)
    // }
    &-open & {
        overflow-x: hidden;
        overflow-y: auto;
        display: block;
        &:before {
            opacity: $modal-backdrop-opacity;
        }
    }
    &-dialog {
        position: absolute;
        width: auto;
        margin: $modal-dialog-margin;
        pointer-events: none; // allow clicks to pass through for custom click handling to close modal
        display: flex;
        align-items: center;
        max-height: subtract(90%, $modal-dialog-margin * 2);
        transform: translate3d(-50%,-50%,0);
        height: auto;
        top: 45%;
        left: 50%;
        min-width: 300px;

        &:before {
            display: block; // IE10
            height: subtract(100vh, $modal-dialog-margin * 2);
            content: "";
        }

        .modal.fade & {
            transition: $modal-transition;
            transform: $modal-fade-transform translate3d(-50%,-50%,0);
        }
        .modal.show & {
            transform: $modal-show-transform translate3d(-50%,-50%,0);
        }
        &_scrollable {
            display: flex; // IE10/11
            max-height: subtract(100%, $modal-dialog-margin * 2);

            .modal{
                &-content {
                    max-height: subtract(100vh, $modal-dialog-margin * 2); // IE10/11
                    overflow: hidden;
                }
                &-header,
                &-footer {
                    flex-shrink: 0;
                }
                &-body {
                    overflow-y: auto;
                }
            }
        }
        &_top {
            align-items: flex-start;
        }
    }
    // Actual modal
    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
        // counteract the pointer-events: none; in the .modal-dialog
        color: $modal-content-color;
        pointer-events: auto;
        background-clip: padding-box;
        outline: 0;
        @if $modal-header-bg != transparent {
            background-color: $modal-content-bg;
            @if $modal-content-border-width > 0 {
                border: $modal-content-border-width solid $modal-content-border-color;
            }
        }
        @if $modal-content-border-radius {
            @include border-radius($modal-content-border-radius);
        }
        @if $modal-content-box-shadow-xs {
            @include box-shadow($modal-content-box-shadow-xs);
        }
        // Remove focus outline from opened modal
    }

    // // Modal background
    // .modal-backdrop {
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   z-index: $zindex-modal-backdrop;
    //   width: 100vw;
    //   height: 100vh;
    //   background-color: $modal-backdrop-bg;

    //   // Fade for backdrop
    //   &.fade { opacity: 0; }
    //   &.show { opacity: $modal-backdrop-opacity; }
    // }

    // Modal header
    // Top section of the modal w/ title and dismiss
    &-header {
        display: flex;
        align-items: stretch; // so the close btn always stays on the upper right corner
        justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
        padding: $modal-header-padding;
        border-bottom: $modal-header-border-width solid $modal-header-border-color;
        @include border-top-radius($modal-content-inner-border-radius);

        .close {
            position: relative;
            min-width: 44px;
            @if $modal-header-extend-btn {
                @extend .btn;
                margin-left: $spacer * 0.5;
            }@else {
                padding: $modal-header-padding;
                margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
            }
            @include icon-glyph('cross-light') {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%,-50%,0);
                font-size: $font-size-lg * 1.125;
            }
        }
    }

    // Title text within header
    &-title {
        margin-bottom: 0;
        // line-height: $modal-title-line-height;
        font-size: $modal-title-font-size;

        @if $modal-header-extend-btn {
            @extend .btn;
        }
    }

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    &-body {
        position: relative;
        // Enable `flex-grow: 1` so that the body take up as much space as possible
        // when there should be a fixed height on `.modal-dialog`.
        flex: 1 1 auto;
        padding: $modal-inner-padding;
        @if $modal-header-bg == transparent {
            background-color: $modal-content-bg;
        }
    }

    // Footer (for actions)
    &-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center; // vertically center
        justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
        padding: $modal-inner-padding - $modal-footer-margin-between / 2;
        border-top: $modal-footer-border-width solid $modal-footer-border-color;
        @if $modal-header-bg == transparent {
            background-color: $modal-content-bg;
        }
        @if $modal-content-inner-border-radius {
            @include border-bottom-radius($modal-content-inner-border-radius);
        }

        // Place margin between footer elements
        // This solution is far from ideal because of the universal selector usage,
        // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
        > * {
            margin: $modal-footer-margin-between / 2;
        }
    }
    @media (min-width:$screen-md){
        &-header .close {
            min-width: 40px;
            font-size: $font-size-lg;
        }
        &-title {
            padding-top: 0.4375 * $spacer;
            min-height: 40px;
        }
    }
}