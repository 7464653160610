.icon-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &-item {
        display: block;
        flex: 1 0 auto;
        align-items: center;
        padding: 10px;

        .icon {
            display: block;
            margin: 0 auto;
        }
    }
    @supports (display: grid) {
		display: grid;
        grid-gap: 20px;
        margin: 0;

		grid-template-columns: repeat(
			auto-fill,
			minmax(128px, 1fr)
        );
        &-item {
            padding: 0;
            .icon {
                width: 100%;
                margin: auto;
            }
        }
	}
}