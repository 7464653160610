ul.card-list {
    padding-left: 0;
    .card:before {
        content: normal;
    }
}

.card-list {
    @extend .block-row;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: $card-list-card-border-width solid $card-list-card-border-color;
        margin-top: $card-list-card-border-width * -1;
        margin-bottom: $card-list-card-border-width;

        &-image {
            max-width: $card-list-card-image-max-width;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: auto;
                max-width: 100%;
                height: auto;
            }
        }
        &-content {
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1 1 auto;
        }
        &-more {
            .btn {
                font-size: 0;
                margin-right: $grid-gutter-width * -.5;
                @extend .btn.btn_arrow-right;
                @media (min-width:$screen-md){
                    display: none;
                }
                &:after {
                    font-size: $font-size-base * 2.6;
                }
                &,
                &:hover,
                &:focus {
                    background-color: transparent;
                    color: $link-color;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
        &-body {
            max-width: 100%;
            flex: 1 1 auto;
            padding: $spacer * 0.875 $spacer * 0.875 $spacer * 1.125 $spacer * 1.125;
            
            p {
                line-height: 1.38;
            }
        }
        &-headline {
            margin-bottom: $spacer * 0.125;
            @extend .h4;
            @media (min-width: $screen-md){
                font-size: $h3-font-size;
            }
        }
    }
    @media (max-width: $screen-sm-max){
        .link-list_inline-plain {
            display: none;
        }
        .card-body {
            padding-right: 0;
        }
    }
}