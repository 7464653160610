
.banner {
    position: relative;
    overflow: hidden;
    z-index: 0;

    .btn {
        display: inline-block;
    }
    > .container {
        z-index: 0;
        position: relative;
        min-height: inherit;
        display: block;
    }
    &-image {
        position: absolute;
        z-index: -1;
        width: auto;
        height: 100%;   
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        
        &_left {
            left: 0;
            transform: translate3d(0,-50%,0);
        }
        &_right {
            left: auto;
            right: 0;
            transform: translate3d(0,-50%,0);
        }
        &_left-outside {
            left: 0;
            transform: translate3d(-12.5%,-50%,0);
        }
        &_right-outside {
            left: auto;
            right: 0;
            transform: translate3d(12.5%,-50%,0);
        }
        &_right-outside,
        &_left-outside {
            @media (min-width: $screen-md){
                transform: translate3d(0,-50%,0);
            }
        }
        &_full-bottom,
        &_full-top,
        &_full {
            height: auto;
            min-width: 100%;
            height: auto;
        }
        &_full-top {
            top: 0;
            transform: translate3d(-50%,0,0);
        }
        &_full-bottom {
            top: auto;
            transform: translate3d(-50%,0,0);
            bottom: 0;
        }
    }
    &-content {
        position: relative;
        z-index: 0;
        padding-top: $spacer * 1.5;
        padding-bottom: $spacer * 2.5;
        max-width: 65%;
        
        &_xs-small {
            max-width: 54%;
        }
        &_xs-large {
            max-width: 90%;
        }
        @media (min-width: $screen-sm){
            max-width: 80%;
            padding-bottom: $spacer * 3;
        }
        @media (min-width: $screen-md){
            max-width: 75%;
            padding-bottom: $spacer * 3.5;
        }
        @media (min-width: $screen-lg){
            max-width: 60%;
        }
        @media (min-width: $screen-xl){
            max-width: 52%;
        }
        
    }
    &-slide {
        width: 100%;
        height: 100%;
        min-height: 100%;
        display: block;
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: absolute;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &-inner {
            max-width: 100%;
            min-height: 100%;
            overflow: hidden;
            width: 100%;
            padding: 0 ($spacer * 0.625);
            position: relative;
            margin-left: auto;
            margin-right: auto;
            z-index: 0;
        }
        &.active {
            opacity: 1;
        }
        &-dots {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            bottom: $spacer * 0.625;
            padding: 0 ($spacer * 0.5);
            width: 100%;
            @media (min-width: $screen-md){
                justify-content: flex-start;
                padding: 0 $spacer;
                bottom: $spacer * 0.75;
                left: 50%;
                transform: translate3d(-50%,0,0);
            }
        }
        @media (min-width: $screen-lg){
            &-inner,
            &-dots {
                max-width: $banner-inner-max-width;
            }
        }
        &-dot {
            border-radius: 50%;
            width: $banner-dot-size;
            height: $banner-dot-size;
            display: block;
            appearance: none;
            padding: 0;
            border: $banner-dot-border-width solid $banner-dot-color;
            transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            margin: $spacer * 0.25;
            background-color: transparent;
            cursor: pointer;

            &.active {
                background-color: $banner-dot-color;
            }
            &:active,
            &:focus {
                outline: none;
            }
        }
        &-dots_light &-dot {
            border-color: $banner-dot-light-color;
            &.active {
                background-color: $banner-dot-light-color;
            }
        }
    }
    &-slides {
        overflow: hidden;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
        height: auto !important;
    }
    &-container {
        position: relative;
        min-height: inherit;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        @media(min-width: $screen-lg){
            max-width: map-get($container-max-widths, 'md');
        }
        &[style*="background-image"]{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            img {
                opacity: 0;
            }
        }
    }
    &,
    &-slides {
        min-height: $banner-slides-min-height;
        position: relative;
        display: block;
        @media (min-width: $screen-s){
            min-height: $banner-slides-s-min-height;
        }
        @media (min-width: $screen-sm){
            min-height: $banner-slides-sm-min-height;
        }
        @media (min-width: $screen-md){
            min-height: $banner-slides-md-min-height;
        }
        @media (min-width: $screen-md){
            min-height: $banner-slides-lg-min-height;
        }
    }
    &-bg {
        &_gray {
            @extend .bg_gray;
        }
        &_orange {
            @extend .bg_orange;
        }
        &_dark {
            @extend .bg_dark;
        }
        &_white {
            @extend .bg_white;
        }
    }
    &-content_light {
        .banner-headline,
        .banner-subheadline {
            color: inherit;
        }
    }
    &-text {
        max-width: 80%;
        display: none;
        
        
        p {
            display: none;
            line-height: 1.25;
        }
        @media (min-width: $screen-sm){
            display: block;
            padding-top: 10px;
        }
        @media (min-width: $screen-md){
            padding-top: 0;
            max-width: 490px;
            p {
                display: block;
            }
            .btn:last-child {
                margin-right: 15%;
                float: right;
            }
        }
    }
    &-headline {
        font-size: $font-size-base * 1.375;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer * 0.25;
        @media (min-width: $screen-md){
            font-size: $h1-font-size;
        }
    }
    &-subheadline {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        @media (min-width: $screen-md){
            font-size: $h1-font-size;
        }
    }
}