// Logo
.logo {
    &_img {
        height: 80px;
        width: auto;
    }
    &:active,
    &:focus {
        outline: none;
    }
}