.costing {
    &-list {
        padding: 0;

        &-item {
            padding: 2px 0;
            display: flex;
            justify-content: space-between;
            &:before {
                content: normal;
            }
            &_border-bottom {
                border-bottom: 1px solid $body-color;
                padding-bottom: 8px;
                margin-bottom: 4px;
            }
            &:first-child {
                padding-top: 0;
            }
        }
    }
    &-label {
        flex: 1 1 auto;
    }
    &-value {
        &_w100 {
            min-width: 100px;
        }
    }
}