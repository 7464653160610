.table-filter {
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-paging {
        display: none;
        padding: 0 $spacer * .5;
        margin-right: 6px;

        span {
            padding-right: 4px;
        }
        a {
            margin: 0 4px 2px;
            &.active {
                &,
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
    &-fields {
        display: none;
        order: 1;
        flex-grow: 1;
        .btn_search {
            color: $white;
        }
    }
    &.active &-fields {
        display: block;
    }
    
    .btn_check {
        justify-self: flex-end;
        order: 1;
        width: 44px;
        height: 44px;
        border-width: 0;
        &:after {
            content: "";
            width: 28px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
            border: 2px solid;
        }
    }
    @media(max-width: $screen-sm-max){
        .btn_filter {
            order: 0;
            background: transparent;
            height: 44px;
            width: 44px;
            position: relative;
            color: $primary;
            font-size: 32px;
            line-height: 1;
            @include icon-glyph('search-light'){
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate3d(-50%,-50%,0);
            }
        }
        .btn {
            &:active,
            &:focus {
                box-shadow: none;
            }
        }
        .block_form {
            background-color: transparent;
            padding: 0 10px 0 4px;
        }
        &.active .btn_check {
            display: none;
        }
        .form-group-item {
            margin-bottom: 0;
        }
        .btn_search {
            &:before {
                display: none;
            }
            &:after {
                display: block;
            }
        }
    }
    @media(min-width: $screen-md){
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-bottom: 0;

        &-fields {
            width: 100%;
            order: auto;
        }
        &-paging {
            display: block;
        }
        .btn_filter {
            position: relative;
            z-index: 1;
            display: block;
        }
        &.active .btn_filter {
            background-image: url(../images/icons/btn_filter_off.svg);
        }
        .btn_check {
            display: none;
        }
    }
}