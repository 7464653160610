.slider {
    &-arrow {
        height: 40px;
        width: 40px;
        appearance: none;
        border: none;
        background: transparent;
        position: absolute;
        transform: translate3d(0, -110%, 0);
        font-size: $font-size-base * 2.375;
        color: $primary;

        &:after {
            @include icon-glyph-defaults(false);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
        }
        &,
        &:hover,
        &:active,
        &:focus {
            outline: none;
            cursor: pointer;
        }

        &s {
            position: absolute;
            top: 50%;
            display: block;
            left: 0;
            right: 0;
            height: 2px;
            transform: translate3d(0, -50%, 0);
        }
        &_prev {
            left: 0;
            @include icon-glyph('arrow_left', ":after");
        }
        &_next {
            right: 0;
            @include icon-glyph('arrow_right', ":after");
        }
        
    }
    &-dot {
        border-radius: 50%;
        width: $banner-dot-size;
        height: $banner-dot-size;
        display: block;
        appearance: none;
        padding: 0;
        border: $banner-dot-border-width solid $banner-dot-color;
        transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        margin: $spacer * 0.25;
        background-color: transparent;

        &.active {
            background-color: $banner-dot-color;
        }
        &:active,
        &:focus {
            outline: none;
        }
        &s {
            position: absolute;
            display: flex;
            justify-content: center;
            bottom: $spacer * 0.625;
            padding: 0 ($spacer * 0.5);
            width: 100%;

            @media (min-width: $screen-md){
                padding: 0 $spacer;
                bottom: $spacer * 0.75;
            }
        }
    }
    @media (min-width: $screen-md){
        &-arrow {
            width: 36px;
            height: 36px;
            font-size: $font-size-base * 2.25;
        }        
    }
}