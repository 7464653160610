.bg {
    &_gray {
        background-color: $gray-light;
    }
    &_light {
        background-color: $gray-beige;
    }
    &_orange {
        background-color: $orange;
        color: $white;
    }
    &_dark {
        background-color: $gray-dark;
    }
    &_plain {
        background-color: transparent;
    }
    &_white {
        background-color: $white;
    }
    &_striped {
        background: transparent $striped-bg-image repeat;
    }
}