/*------------------------------------*\
    #MIXINS
\*------------------------------------*/

/** CSS Transition
 *	Usage: @include transition(width,0.3s,ease-out);
 */
@mixin transition($transition-property, $transition-time, $method) {
	-webkit-transition: $transition-property $transition-time $method;
	-moz-transition: $transition-property $transition-time $method;
	-ms-transition: $transition-property $transition-time $method;
	-o-transition: $transition-property $transition-time $method;
	transition: $transition-property $transition-time $method;
}

/** Rem Unit font sizes with relative fallback http:/seesparkbox.com/foundry/scss_rem_mixin_now_with_a_better_fallback  
 *	Usage: @include font-size(1, large);
 */
@mixin font-size( $decimal-size, $keyword: null ) {
  @if $keyword{ font-size: $keyword; }
  @else { font-size: $decimal-size * $base-font-multiplier * 16px;}
  font-size: $decimal-size * 1rem;
}
@mixin icon-glyph-defaults ($selection:":before"){
	@if($selection){
		&#{$selection} {
			font-family: $font-family-icons;
			font-weight: normal;
			font-style: normal;
			@content;
		}
	} @else {
		font-family: $font-family-icons;
		font-weight: normal;
		font-style: normal;
		@content;
	}
}

@mixin icon-glyph ($value, $selection:":before"){
	@if($selection){
		&#{$selection} {
			content: "\\#{map-get($icon-content, $value)}";
			@include icon-glyph-defaults(false);
			@content;
		}
	}@else {
		content: "\\#{map-get($icon-content, $value)}";
		@content;
	}
}
@mixin icon-glyph-list($values:$icon-content, $selection:":before"){
	@each $key, $value in $values {
		&_#{$key}{
			&#{$selection} {
				@include icon-glyph($key, false);
			}
		}
	}
}

@mixin placeholder($color:false){
	&::-webkit-input-placeholder {
		@if $color {
			color: $color;
		}
		@content;
	}
	&::placeholder {
		@if $color {
			color: $color;
		}
		@content;
	}
	&:-ms-input-placeholder {
		@if $color {
			color: $color;
		}
		@content;
	}
	&::-ms-input-placeholder {
		@if $color {
			color: $color;
		}
		@content;
	}
}

@mixin innerBlockWidth($columnOuter,$columnInner,$attribute:'max-width'){
	$relationSelf: 100/12 * (12 - $columnOuter);
	$relationTaken: (100/12 * $columnOuter)/12 * $columnInner;
	$percentageTarget: 100 - $relationTaken;
	$percentageSelf: $percentageTarget/$relationSelf * 100;
	$percentageGround: floor($percentageSelf);
	$percentageAddition: (floor(($percentageSelf - $percentageGround) * 100))/100;
	$percentageResult: ($percentageGround + $percentageAddition) * 1%;
	&-#{$columnOuter}-#{$columnInner} {
		#{$attribute}: $percentageResult;
	}
}

@mixin innerBlockWidths($attribute:'max-width'){
	@include innerBlockWidth(11,10,$attribute);
	@include innerBlockWidth(11,8,$attribute);
    @include innerBlockWidth(10,10,$attribute);
    @include innerBlockWidth(10,8,$attribute);
    @include innerBlockWidth(8,10,$attribute);
    @include innerBlockWidth(8,8,$attribute);
}

@mixin delimiterBorder(){
	color: $link-list-delimited-item-delimiter-color;
	height: $link-list-delimited-item-delimiter-height;
	background: $link-list-delimited-item-delimiter-background;
	background-image: $link-list-delimiter-item-delimiter-background-image;
	background-position: left center, center, right center;
	background-size: $link-list-delimited-item-delimiter-gradient-size $link-list-delimited-item-delimiter-height, calc(100% - #{$link-list-delimited-item-delimiter-gradient-size * 2}) $link-list-delimited-item-delimiter-height, $link-list-delimited-item-delimiter-gradient-size $link-list-delimited-item-delimiter-height;
}

@mixin set-row(){
	@include make-row();
    > * {
      @include make-col-ready();
    }
}