
.visible {
    &-s {
        @media (max-width: $screen-xs-max){
            display: none !important;
        }
    }
    &-md {
        @media (max-width: $screen-sm-max){
            display: none !important;
        }
    }
    &-sm {
        @media (max-width: $screen-s-max){
            display: none !important;
        }
    }
    &-lg {
        @media (max-width: $screen-md-max){
            display: none !important;
        }
    }
}
.hidden {
    display: none;
    &-xs {
        @media (max-width: $screen-xs-max){
            display: none !important;
        }
    }
    &-s {
        &_g{
        @media (min-width: $screen-s){
            display: none !important;
        }} 
        @media (min-width: $screen-s) and (max-width: $screen-s-max){
            display: none !important;
        }
    }
    &-sm {
        &_g{
        @media (min-width: $screen-sm){
            display: none !important;
        }}
        @media (min-width: $screen-sm) and (max-width: $screen-sm-max){
            display: none !important;
        }
    }
    &-md {
        &_g{
        @media (min-width: $screen-md){
            display: none !important;
        }}
        @media (min-width: $screen-md) and (max-width: $screen-md-max){
            display: none !important;
        }
    }
    &-lg {
        &_g{
            @media (min-width: $screen-lg){
                display: none !important;
            }
        }
        @media (min-width: $screen-lg) and (max-width: $screen-lg-max){
            display: none !important;
        }
    }
}