.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.nobr {
  white-space: nowrap;
}

.hr {
  border-top: $footer-hr-border-top;
  border-bottom: $footer-hr-border-bottom;
  background: transparent;
  margin: $footer-hr-margin;
  display: block;
  opacity: 1;
}

.block-row {
  @include set-row();
}

.block {
  padding: ($spacer * 0.825);
  &_p {
    padding: $spacer;
    &y {
      padding-top: $spacer;
      padding-bottom: $spacer;
    }
    &x {
      padding-left: $spacer;
      padding-right: $spacer;
    }
  }
  &_form {
    &,
    &-full-sm,
    &-full-md {
      @extend .block_p;
      @extend .bg_light;
      hr {
        @extend .hr;
      }
    }
    &-full{
      &-sm {
        @media (max-width: $screen-sm-max){
          margin-left: $container-padding-x * -1;
          margin-right: $container-padding-x * -1;
          padding-left: $container-padding-x;
          padding-right: $container-padding-x;
        }
      }
      &-md {
        @media (max-width: $screen-md-max){
          margin-left: $container-padding-x * -1;
          margin-right: $container-padding-x * -1;
          padding-left: $container-padding-x;
          padding-right: $container-padding-x;
        }
      }
    }
  }
  &_column {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &_flex {
    &,
    &-center {
      flex: 1 1 auto;
    }
    &-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &_row {
    &,
    &-between,
    &-end {
      display: flex;
    }
    &-end {
      justify-content: flex-end;
    }
    &-between {
      justify-content: space-between;
    }
    &.headline_text {
      > .span,
      > span {
        margin-left: 0.3em;
      }
    }
  }
  &-inner {
    padding: ($spacer * 0.825);
    p:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: $screen-md) {
    &-md {
      display: block;
      &_p {
        &y {
          padding-top: $spacer;
          padding-bottom: $spacer;
        }
        &x {
          padding-left: $spacer;
          padding-right: $spacer;
        }
      }
      &_np {
        &x {
          padding-left: 0;
          padding-right: 0;
        }
        &y {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    &_column-md {
      display: flex;
      flex-direction: column;
    }
    &_row-md {
      display: flex;
      flex-direction: row;
    }
  }
  @media (min-width: $screen-sm) {
    &-sm {
      display: block;
      &_p {
        &y {
          padding-top: $spacer;
          padding-bottom: $spacer;
        }
        &x {
          padding-left: $spacer;
          padding-right: $spacer;
        }
      }
    }
    &_column-sm {
      display: flex;
      flex-direction: column;
    }
    &_row-sm {
      display: flex;
      flex-direction: row;
    }
  }
  &-header {
    @include btnDefault();
    display: inline-block;
    font-size: $font-size-sm;
    margin-bottom: 0;
    font-weight: 300;
    line-height: 1.43;

    @media (min-width: $screen-md){
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      line-height: 1.40;
    }
    @media (max-width: $screen-sm-max){
      &_full-left-sm {
        margin-left: ($spacer * 0.625) * -1;
      }
    }
  }
}
.img-block {
  max-width: 100%;
  height: auto;
  padding: ($spacer * 0.825) 0;
  &:first-child {
    padding-top: 0;
  }
  &_w120 {
    max-width: 120px;
    width: 100%;
  }
  &_w150{
    max-width: 150px;
    width: 100%;
  }
  &_w180 {
    max-width: 180px;
    width: 100%;
  }
  &_w200 {
    max-width: 200px;
    width: 100%;
  }
  &_mw {
    &50 {
      min-width: 48px;
    }
    &120 {
      min-width: 120px;
    }
    &150 {
      min-width: 150px;
    }
  }
  &_over {
    @include innerBlockWidths;
    @media (min-width: $screen-sm) {
      &-sm {
        @include innerBlockWidths;
      }
    }
    @media (min-width: $screen-md) {
      &-md {
        @include innerBlockWidths;
      }
    }
    @media (min-width: $screen-lg) {
      &-lg {
        @include innerBlockWidths;
      }
    }
  }
  .img {
    max-width: 100%;
    height: auto;
    &_over-top {
      margin-top: -25%;
    }
  }
  &.img-block_bottom {
    display: flex;
    align-items: flex-end;
    padding-top: 0;

    &-small {
      padding-top: 0;
      padding-bottom: ($spacer * 0.5);
    }
  }
}
span.img-block {
  display: block;
  svg {
    width: 100%;
    height: auto;
  }
  &.icon svg {
    fill: currentColor;
  }
}
img.img-block {
  margin-top: ($spacer * 0.825);
  margin-bottom: ($spacer * 0.825);
  padding: 0;
  &:first-child {
    margin-top: 0;
  }
  &_full {
    width: 100%;
    height: auto;
  }
  &_bottom {
    margin-bottom: ($spacer * 0.825);
    padding: 0;
  }
}
.no-overflow {
  overflow: hidden;
}

.no-space {
  margin-bottom: 0;
  margin-top: 0;
}

p.half-space {
  margin-bottom: 0.5rem;
}

//adding flex: 1 1 auto option for columns (at each breakpoint)
.col-flex {
  flex: 1 1 auto;
  @media (min-width: $screen-md){
    &-md {
      flex: 1 1 auto;
    }
  }
}
.col {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &#{$infix}-flex {
        flex: 1 1 auto;
      }
    }
  }
  &_block {
    position: relative;
    display: flex;
    justify-content: flex-start;

    &:last-child {
      justify-content: flex-end;
    }
    .img-block {
      &:only-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &_over {
        overflow: hidden;
        img {
          max-width: 160%;
        }
      }
    }
  }
}

//video content
.video {
  width: 100%;
  height: auto;
  &-embed {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    &_4-3 {
      padding-top: 75%;
    }
  }
  &-embed &-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}