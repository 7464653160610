// button
@mixin btnDefault(){
  display: block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $btn-color;
  text-align: center;
  white-space: $btn-white-space;
  text-decoration: none;
//  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  background-color: $btn-bg;
  border: $btn-border-width solid $btn-border-color;
  padding: $btn-padding-y $btn-padding-x;
//  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
//  @include transition($btn-transition);
}

@mixin btnActivity(){
  &:hover {
    color: $btn-hover-bg;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  &:disabled,
  &.disabled,
  fieldset:disabled & { // stylelint-disable-line selector-no-qualifying-type
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
}

.btn {
  @include btnDefault();
  @include btnActivity();

  &_inline {
    &,
    &-right {
      display: inline-block;
    }
    &-right {
      float: right;
    }
  }
  &_half {
    &,
    &-right,
    &-left {
       min-width: 50%;
    }
  }
  &_third {
    &,
    &-right,
    &-left {
      min-width: 33.33%;
    }
  }
  &_spaced {
    &-top {
      margin-top: $spacer;
    }
    &-bottom {
      margin-bottom: $spacer;
    }
  }
  &_half-left,
  &_third-left,
  &_stretch-left,
  &_left {
      text-align: left;
  }
  &_half-right,
  &_third-right,
  &_stretch-right,
  &_right {
      text-align: right;
  }
  &_stretch {
    &,
    &-right,
    &-left {
      width: 100%;
    }
  }
  &_round {
    border-radius: $btn-round-border-radius;
    @if $btn-round-white-space {
      white-space: $btn-round-white-space;
    }
  }
  &.btn_secondary {
    background-color: $btn-secondary-bg;
  }
  &.btn_text {
    background-color: transparent;
    color: $primary;
    padding-left: 0;
    padding-right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:focus {
      box-shadow: none;
    }
    svg {
      max-height: 24px;
      width: auto;
      height: auto;
    }
    .icon {
      display: block;
      float: left;
      margin-right: 0.25rem;
      margin-left: 0;
      svg {
        fill: currentColor;
      }
      &:only-child {
        margin-right: 0;
      }
    }
  }
  &.btn_plain {
    background-color: transparent;
    color: $body-color;
    &:focus {
      box-shadow: none;
    }
  }
  &.btn_icon{
    &-small {
      svg {
        max-height: 18px;
      }
    }
    &-large {
      svg {
        max-height: 32px;
      }
    }
    &-right {
      .icon {
        order: 1;
        margin-left: 0.25rem;
        margin-right: 0;
      }
    }
  }
  &.btn_icon,
  &.btn_arrow-right {
    padding-right: $spacer * 1.75;
    position: relative;
    
    &:after {
      @include icon-glyph-defaults(false);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0,-50%,0);
    }
    &:empty {
      padding-right: $btn-padding-x * 1.125;
      width: 44px;
      height: 44px;
      &:after {
        right: auto;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
      }
    }
  }
  &.btn_icon {
    &:after {
      font-size: $font-size-base * 2;
    }
  }
  &_icon {
    &-plus:after {
      @include icon-glyph('plus-light', false);
    }
    &-minus:after {
      @include icon-glyph('minus-light', false);
    }
    &-edit:after {
      @include icon-glyph('tab-edit', false);
    }
    &-more:after {
      @include icon-glyph('tab-more', false);
    }
    &-duplicate:after {
      @include icon-glyph('tab-duplicate', false);
    }
    &-check:after {
      @include icon-glyph('check', false);
    }
    &-close:after {
      @include icon-glyph('close', false);      
    }
    &-close,
    &-check {
      &.btn_left {
        padding-right: 2.25rem;

        &:after {
          font-size: $font-size-base;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
    }
    &-check {
      &.btn_left {
        &:after {
          transform: translate3d(0,-50%,0) rotate(15deg);
        }
      }
    }
    &.btn_round-sm {
      border-radius: 14px;

      &:empty {
        width: 28px;
        padding: 13px;
        min-height: 28px;
        height: 28px;
      }
    }
  }
  &.btn_close {
    @extend .btn_icon;
    &:after {
      @include icon-glyph('cross-light', false);
      font-size: $font-size-base * 1.4;
    }
  }
  &.btn_search {
    padding: 0 22px;
    position: relative;

    @include icon-glyph('search-light') {
        transform: translate3d(-50%,-50%,0);
        font-size: $font-size-base * 2;
        position: absolute;
        top: 50%;
        left: 50%;
    }
    &:after {
      display: none;
    }
  }
  &.btn_arrow-right {
    &:after{
      @include icon-glyph('arrow_right', false) {
          transform: translate3d(0,-50%,0);
          font-size: $font-size-base * 2;
      }
    }
  }
  &.btn_arrow-left {
    padding-left: $spacer * 1.75; 
    position: relative;
    
    @include icon-glyph('arrow_left') {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0%,-50%,0);
        font-size: $font-size-base * 2;
    }
    &:empty {
      padding-left: $btn-padding-x * 1.125;
      width: 44px;
      height: 44px;
      &:before {
        left: 50%;
        transform: translate3d(-50%,-50%,0);
      }
    }
  }
  &.btn_arrow-left,
  &.btn_arrow-right {
    @media(max-width: $screen-sm-max){
      min-height: $font-size-base * 2.75;
    }
  }
  &_toggle-collapse {
    display: block;
    text-align: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 0;
    min-height: $btn-toggleCollapse-size;
    color: $btn-toggleCollapse-color;
    overflow: visible;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: $btn-toggleCollapse-size * 0.6;
      box-sizing: border-box;
      opacity: 0.5;
      background-color: $btn-toggleCollapse-bg-color;
      box-shadow: $btn-toggleCollapse-box-shadow;
      transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
      .collapsed & {
        box-shadow: $btn-toggleCollapse-collapse-box-shadow;
        opacity: 1;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: $btn-toggleCollapse-size * 0.6;
      box-sizing: border-box;
      top: 50%;
      background: linear-gradient(to right, currentColor 0%, currentColor 50%, transparent 50%, transparent 100%) repeat-x left top, left bottom;
      background-color: $body-bg;
      background-image: linear-gradient(to right, currentColor 0%, currentColor 50%, transparent 50%, transparent 100%);
      background-size: 4px 1px;
      z-index: 0;
    }
    span {
      position: relative;
      width:  $btn-toggleCollapse-size;
      height: $btn-toggleCollapse-size;
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: $btn-bg;
      color: $btn-color;
      z-index: 1;
      @include icon-glyph-defaults() {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        font-size: $font-size-base * 2;
        @extend .btn_icon-minus:after;
      }
      .collapsed &:before {
        @extend .btn_icon-plus:after;
      }
    }
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &_bordered {
    &,
    &:hover,
    &:focus,
    &:active,
    &:disabled {
      border-color: $btn-bordered-border-color;
    }
  }
  &-label {
    color: $primary;
  }
  &-menu {
    appearance: none;
    border: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &_burger {
      width: 44px;
      display: block;
      background: transparent url(../images/icons/burger-menu.svg) no-repeat center;
      background-size: 44px auto;
      &.active {
        background-color: $menu-btn-bg-color;
      }
    }
  }
  &_filter {
    background-image: url(../images/icons/btn_filter.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 34px;
  }
  &_check {
    @include icon-glyph('cross-light'){
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      font-size: 18px;
      line-height: 1;
    }
    height: 28px;
    width: 28px;
    position: relative;
    background: transparent;
    border: 2px solid;
    &,
    &:active,
    &:hover,
    &:focus {
      color: $primary;
    }
  }
  @media (max-width: $screen-sm-max){
    &.btn_search {
      min-width: 44px;
      
      &:empty {
        width: 44px;
        height: 44px;
      }
    }
  }
  @media (min-width: $screen-sm){
    &_inline-sm-right,
    &_inline-sm {
      display: inline-block;
    }
    &_inline-sm-right {
      float: right;
    }
  }
  @media (min-width: $screen-md){
    padding: $input-btn-padding-y-sm $btn-md-padding-x;
    min-height: $input-height-sm;
    &_toggle-collapse { padding: 0; }
    &-menu_burger {
      display: none;
    }
    &_inline-md-right,
    &_inline-md {
      display: inline-block;
    }
    &_inline-md-right {
      float: right;
    }
    &.btn_icon,
    &.btn_close,
    &.btn_arrow-right,
    &.btn_arrow-left {
      &:empty {
        width: 34px;
        height: 34px;
      }
    }
    &_round {
      border-radius: $btn-round-border-radius;

      &-sm.btn_icon:empty {
        width: 28px;
        height: 28px;
      }
    }
    &.btn_search {
      padding: 0 16px;
      min-width: 34px;
      box-sizing: border-box;
      &:before {
        font-size: $font-size-base * 1.65;
        margin-top: 1px;
      }
    }
  }
}

a,
.link {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
  }
}