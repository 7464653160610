@font-face {
  font-family: $font-name-base;
  src: url("fonts/EnzoOffc-Light.eot");
  src: url("fonts/EnzoOffc-Light.eot?#iefix") format("embedded-opentype"), url("fonts/EnzoOffc-Light.woff") format("woff"), url("fonts/EnzoOffc-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-name-base;
  src: url("fonts/EnzoOffc-Light.eot");
  src: url("fonts/EnzoOffc-Light.eot?#iefix") format("embedded-opentype"), url("fonts/EnzoOffc-Light.woff") format("woff"), url("fonts/EnzoOffc-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: $font-name-base;
  src: url("fonts/EnzoOffc-Medium.eot");
  src: url("fonts/EnzoOffc-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/EnzoOffc-Medium.woff") format("woff"), url("fonts/EnzoOffc-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: $font-name-base;
  src: url("fonts/EnzoOffc-Medium.eot");
  src: url("fonts/EnzoOffc-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/EnzoOffc-Medium.woff") format("woff"), url("fonts/EnzoOffc-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: $font-name-base;
  src: url("fonts/EnzoOffc-Medium.eot");
  src: url("fonts/EnzoOffc-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/EnzoOffc-Medium.woff") format("woff"), url("fonts/EnzoOffc-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: $font-name-base;
  src: url("fonts/EnzoOffc-Medium.eot");
  src: url("fonts/EnzoOffc-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/EnzoOffc-Medium.woff") format("woff"), url("fonts/EnzoOffc-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: $font-name-icons;
  src: url("fonts/iloxx-icons.eot");
  src: url("fonts/iloxx-icons.eot?#iefix") format("embedded-opentype"), url("fonts/iloxx-icons.woff") format("woff"), url("fonts/iloxx-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}