.footer {
    background-color: $footer-bg-color;
    padding: $footer-padding;
    &,
    div,
    p {
        font-size: $footer-element-font-size;
        color: $footer-element-color;
    }
    hr {
        @extend .hr;
    }
    &-copy {
        p {
            margin-bottom: 0; 
            margin-top: 0;
        }
    }
    .nav-list-headline {
        margin-bottom: $spacer * 0.625;
        display: block;
    }
    .link-list_inline li {
        margin-right: $spacer * 1.75;
    }
    .link-list_icons {
        margin-bottom: ($spacer * 0.75);
        margin-top: $spacer;
        justify-content: center;
        @media(min-width: $screen-sm){
            margin-top: $spacer * 2;
            justify-content: flex-end;
        }
        img {
            min-width: 32px;
            max-width: 48px;
        }
        a {
            display: block;
            text-align: center;
        }
    }
    @media (max-width: $screen-sm-max){
        .header_mobile-side-nav-bottom ~ & {
            padding-bottom: 104px;
        }
    }
    @media (min-width: $screen-md){
        padding: $footer-padding-md;
    }
}
.nav-list_footer {
    font-size: $footer-element-font-size;
    color: $footer-element-color;
    line-height: 1.27;

    a {
        text-decoration: none;

        &,
        &:hover,
        &:active,
        &:focus {
            color: inherit;
        }
    }
    &,
    li {
        color: inherit;
    }
}