.nav-list {
    &-item .nav-link {
        text-decoration: none;
        &,
        &:hover,
        &:active,
        &:focus {
            color: inherit;
        }
    }
    &_inline {
        display: flex;
        flex-wrap: wrap;
    }
    &_inline &-item {
        margin: ($spacer *0.625);
    }
    &-node-item {
        > .nav-link {
            padding-right: ($spacer * 1.125);
            position: relative;
            
            @include icon-glyph('arrow_down',':after') {
                position: absolute;
                margin-right: $spacer * -1;
                right: $spacer * 1.5;
                font-size: $font-size-sm;
                top: 50%;
                transform:translate3d(0,-50%,0);
                padding-top: 2px;
            }
        }
        @media (max-width: $screen-sm-max){
            &.active > .nav-link {
                &:after {
                    @include icon-glyph('arrow_up', false);
                }
            }
        }
    }
    &_main,
    &_side {
        padding-left: 0;
    }
    &_main {
        background-color: $header-nav-bg-color;
        padding: ($spacer * 0.125) 0;
        color: $header-nav-color;
        &.current,
        ul.current {
            color: $header-nav-current-color;
            > .nav-list-item {
                color: inherit;
            }
        }
        .nav-link {
            text-decoration: none;
            padding: ($spacer * 0.25) ($spacer * 0.625);
            display: block;
            color: inherit;
        }
    }
    &_main & {
        color: $header-nav-color;
        &-item {
            padding-left: 0;
            padding: ($spacer * 0.1875) 0;
            position: relative;

            &.active > .nav-link {
                color: $header-nav-active-color;
            }
        }
    }
    @media (max-width: $screen-sm-max){
        &_main &-item {
            &:before {
                content: "";
                border-top: $header-subnav-border-top;
                border-bottom: $header-subnav-border-bottom;
                position: absolute;
                left: ($spacer * 0.625);
                right: 0;
                top: -1px;
                margin: 0;
            }
            &:first-child:before {
                content: normal;
            }
        }
        @if ($header-nav-current-hover-color != $header-nav-hover-color) {
            &-item > a:hover {
                color: $header-nav-hover-color;
            }
            &.current > &-item {
                > a:hover {
                    color: $header-nav-current-hover-color;
                }
            }
        }
        @if ($header-nav-current-hover-color == $header-nav-hover-color) {
            &.current > &-item > a:hover,
            &-item > a:hover {
                color: $header-nav-hover-color;
            }
        }
    }
    &_side {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $header-nav-bottom-sticky-color;
        background-color: $header-nav-bottom-sticky-bg-color;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0 ($spacer * 0.5);

        .icon {
            width: 38px;
            height: 38px;
            line-height: 1;
            font-size: 36px;
            @include icon-glyph-defaults(){
                position: absolute;
            }
            &_tracking:before {
                @include icon-glyph('tracking', false);
            }
            &_login:before {
                @include icon-glyph('login', false);
            }
            &_startorder:before {
                @include icon-glyph('startorder', false);
            }
            &_img {
                max-height: 38px;
                height: auto;
                width: auto;
            }
        }
    }
    &_side &-item {
        padding: 0;

        &:before {
            content: normal;
        }
        .nav-link {
            padding: ($spacer * 0.625) ($spacer * 0.25) ($spacer * 0.5);
            display: flex;
            flex-direction: column;
            color: inherit;
            justify-content: center;
            align-items: center;
        }
        .label {
            display: none;
        }
        
    }
    @media (min-width: $screen-md) {
        &_main {
            background: transparent;
            &.current,
            ul.current {
                color: inherit;
            }
        }
        &_main & {
            &-item {
                &:before {
                    content: normal;
                }
                &.active > a {
                    color: inherit;
                }
            }
        }
        &_side {
            background: transparent;
            justify-content: flex-end;
            position: static;
        }
    }
}