/*------------------------------------*\
	#COLORS
\*------------------------------------*/
$orange: #e56a19;		// iloxxOrange
$gray-dark: #4a4a49;	// iloxxBlack
$gray: #6f6f6f;			// copyGray
$gray-medium: #808285;	// new, used for gray select-box arrow
$gray-light: #ececec;	// lightGray 
$beige: #e5d8b7;		// iloxxBeige
$beige-dark: #b2a78e;	// darkBeige
$gray-beige: #f7f6f3;	// grayBeige
$gray-beige2: #f0eee8;	// grayBeige2
$gray-beige-medium: #e9e6df; // used on footer
$gray-beige3: #e4e2dd; // used for border
$white: #ffffff;		// iloxxWhite
$white-medium: #f2f2f2;	// selectorGray
$white-soft: #fbfbf9;	// new, used for disabled inputs
$red: #e52019;			// warningRed
// scss-docs-start colors-map
$colors: (
	'orange' :	$orange,		// iloxxOrange
	'gray-dark' :	$gray-dark,		// iloxxBlack
	'gray' :	$gray,			// copyGray
	'gray-light' :	$gray-light,	// lightGray
	'gray-medium' : $gray-medium, //
	'beige' :	$beige,			// iloxxBeige
	'beige-dark' :	$beige-dark,	// darkBeige
	'gray-beige' :	$gray-beige,	// grayBeige
	'gray-beige2' :	$gray-beige2,	// grayBeige2
	'$gray-beige3': $gray-beige3,
	'white' :	$white,			// iloxxWhite
	'white-medium' :	$white-medium,	// selectorGray
	'white-light' : $white-light, //
	'red' :		$red			// warningRed
) !default;
// scss-docs-end colors-map

$primary:       $orange;
$secondary:     $gray;
$success:       $green;		//to be defined
$info:          $gray-beige2;		//to be defined
$warning:       $orange;	//to be defined
$danger:        $red;
$light:         $gray-light;
$dark:          $gray-dark;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;