ul {
    list-style: none;
    padding-left: $spacer * 0.85;
    
    li {
        list-style: none;

        @include icon-glyph('arrow_right-light') {
            position: absolute;
            margin-left: $spacer * -1;
        }
    }
}
//
// Lists
//
.list {
    &_plain,
    &_unstyled {
        li:before {
            content: normal;
        }
    }
    &_unstyled {
        @include list-unstyled();
    }
    // Inline turns list items into inline-block
    &_inline {
        @include list-unstyled();
    }
    &-item_inline {
        display: inline-block;

        &:not(:last-child) {
            margin-right: $list-inline-padding;
        }
    }
    &_feature {
        padding-left: $spacer * 1.25;
        box-sizing: border-box;

        li:before {
            color: $list-feature-prefix-color;
            margin-left: $spacer * -1.4;
            @include icon-glyph('plus', false);
        }
    }
    &-block {
        box-shadow: $btn-toggleCollapse-box-shadow;
        .list_feature {
            margin-bottom: 0;
            position: relative;
            max-height: 800px;
            transition: max-height 0.25s ease-in-out;
        }
        &.collapsed {
            box-shadow: $btn-toggleCollapse-box-shadow;
            .list_feature{
                max-height: 0;
            }
        }
    }
}