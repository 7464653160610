.accordion {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &-header {
        margin-top: $spacer *1.25;
        padding: $spacer * 0.5 $spacer * 0.625;
        position: relative;
        background-color: $gray-light;
        display: block;
        padding-right: $spacer * 2.25;
        
        @include icon-glyph('plus-light', ':after') {
            position: absolute;
            top: 50%;
            right: $spacer * 0.5;
            transform: translate3d(0, -50%,0);
            font-size: $font-size-base * 1.75;
            min-width: 28px;
            padding-top: 0.125 * $spacer;
            line-height: 24px;
            text-align: center;
        }
        
        &:first-child {
            margin-top: 0;
        }
        &.active:after {
            @include icon-glyph('minus-light', false);
        }
        .headline {
            @extend .h5;
            display: block;
            max-width: 100%;
            overflow: hidden;
        }
    }
    &-body {
        max-height: 0;
        height: auto;
        overflow: hidden;
        opacity: 0;
        display: block;
        position: relative;
        background-color: $gray-beige;
        transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in;
    }
    // &-content {
    //     padding: $spacer;
    // }
    &-header.active + &-body {
        max-height: 1000px;
        height: auto;
        opacity: 1;
        transition: max-height 0.6s ease-in-out, opacity 0.1s ease-in;
    }
    @media (min-width: $screen-md) {
        &-header {
            padding: $spacer * 0.75 $spacer;
        }
    }
}