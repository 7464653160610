.alert {
    &-container {
        padding: $alert-padding-y $alert-padding-x $alert-padding-y;
        position: relative;
        top: -1px;

        > p:last-child {
            margin-bottom: $spacer * 0.25;
        }
        > p:only-child {
            margin-bottom: 0;
        }
    }
    &-close {
        appearance: none;
        border: none;
        background: transparent;
        color: $alert-close-color;
        position: absolute;
        right: $alert-padding-x;
        top: $alert-padding-y;
        &,
        &:active,
        &:focus {
            outline: none;
        }
        @include icon-glyph('cross-light'){
            // position: absolute;
            // left: 50%;
            // top: 50%;
            //transform: translate3d(-50%,-50%,0);
        }
        // cross-light
    }
    &_dismissible &-container {
        padding-right: $spacer * 2.25;
    }
    &_dismissible {
        transition: opacity 0.3s ease-in-out, display 0.1s 0.3s ease-in, margin-top 0.3s ease-in, top 0.3s ease-in;
        opacity: 1;
        max-height: 75vh;
        &.hide {
            opacity: 0;
            transition: opacity 0.3s ease-in-out, display 0.1s 0.3s ease-in, margin-top 0.3s ease-in, top 0.3s ease-in, max-height 0.3s 0.3s ease-in;
            max-height: 0;
        }
    }
    &_sm {
        font-size: $font-size-sm;
    }
    &_s {
        font-size: $font-size-s;
    }
    &_info {
        background-color: $alert-info-bg-color;
        color: $alert-info-color;
    }
    &_highlight,
    &_note {
        @extend .bg_striped;
    }
    &_highlight {
        border: $alert-highlight-border;
    }
    &_warning {
        background-color: $alert-warning-bg-color;
        color: $alert-warning-color;
    }
    &_warning &-close {
        @extend .alert-close;
    }
    &_error {
        background-color: $alert-error-bg-color;
        color: $alert-error-color;        
    }
    &_success {
        background-color: $alert-success-bg-color;
        color: $alert-success-color; 
    }
    &_success &-close,
    &_error &-close {
        color: inherit;
    }
    body > & {
        &:first-child {
            position: fixed;
            top: 44px;
            z-index: 1;
            width: 100%;
            
            // @media (min-width: $screen-md){
            //     position: relative;
            //     top: 0;
            // }
        }
    }
    @media (max-width: $screen-sm-max){
        &_note {
            background: $gray-beige;
            padding-top: $spacer * 1.125;
            padding-bottom: $spacer * 0.75;
            position: relative;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate3d(-50%,0,0);
                border-top: 18px solid $body-bg;
                border-right: 20px solid transparent;
                border-bottom: 0 solid transparent;
                border-left: 20px solid transparent;
            }
        }
    }
    @media (min-width: $screen-md){
        body > &:first-child {
            position: relative;
            top: 0;
        }
        &_highlight &-container,
        &_note &-container {
            padding: $alert-note-md-padding;
        }
    }
}