.step-list {
    @extend .block-row;

    &-item {
        position: relative;
        min-height: 50px;
        display: block;
        margin-top: $spacer * 0.625;
        flex: 1 0 0%;
        min-width: 0;
        @extend .col-sm-6;
        @extend .col-lg-4;

        &:before {
            display: none;
        }
        @media (max-width: $screen-s-max){
           flex: 1 1 100%;
        }
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }
    &-image {
        margin: 0 0 $spacer * 0.625;
        img{
            min-width: 80%;
            position: relative;
            z-index: -1;
            max-width: 100%;
        }
    }

    &_enum {
        counter-reset: step-list-counter;
    }
    &_enum &-item {
        counter-increment: step-list-counter;
        
        &:before {
            content: counter(step-list-counter) ".";
            font-family: $font-name-base;
            display: block;
            transform: none;
            box-sizing: border-box;
        }
    }
    &-numeration,
    &_enum &-item:before {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        box-sizing: border-box;
        line-height: 48px;
        padding-left: 4px;
        font-size: 24px;
        color: $steplist-numeration-color;
        background-color: $steplist-numeration-bg-color;
        text-align: center;
        position: absolute;
        display: block;
        margin: 0;
        left: $spacer * 0.625;
        top: $spacer * 0.125;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}