.tracking {
    &-details {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &-block {
        margin: 1rem 0;
        padding: 0;
        position: relative;
        z-index: 2;
        overflow: hidden;
        min-height: 54px;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    &-mockImage {
        width: auto;
        max-width: 100%;
        position: absolute;
        z-index: -1;
    }
    &-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .325rem;
        line-height: 1.36;

        > span {
          display: block;
          padding-right: 0.5rem;
        }
        &-link {
            align-self: flex-end;
            margin-left: $spacer * 0.85;
            @include icon-glyph('arrow_right-light') {
                position: absolute;
                margin-left: $spacer * -1;
            }
        }
        &-title {
            flex: 1 1 auto;
            width: 75%;
        }
    }
    &-step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $primary;
        position: relative;
        margin: 0 auto;
        img,
        svg {
            max-width: 48px;
            max-height: 48px;
            height: auto;
            margin: 0 auto;
            display: block;
        }
        svg {
            fill: currentColor;
        }
        &:before {
            content: normal;
        }
        &-icon {
            max-width: 100%;
            max-height: 80px;
            width: 48px;
            height: 48px;
            margin: 0 auto;
            display: block;

            svg,
            img {
                max-height: auto;
                max-width: 100%;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%,-50%,0);
            }
            &_md,
            &_sm,
            &_s {
                display: none;
            }
        }
        &-dot {
            width: 24px;
            height: 24px;
            display: block;
            margin: 2px auto 0;
            border-radius: 50%;
            border: 12px solid;
            position: relative;
            transition: border-width 0.2s ease-in, width 0.2s ease-in, height 0.2s ease-in;

            &:after,
            &:before {
                content: "";
                position: absolute;
                height: 4px;
                top: 0px;
                width: 100vw;
                border-radius: 2px;
                border: 2px solid;
                font-size: 0;
                z-index: -1;
                transform: translate3d(0%, -50%, 0);
                margin: 0 auto;
                line-height: 0;
                display: block;
            }
            &:before {
                transform: translate3d(-100%, -50%, 0);
                z-index: 0;
            }
            &:after {
                color: $body-color;
            }
        }
        &.current ~ & {
            color: $body-color;
        }
        &.current ~ & &-dot {
            &:before {
                z-index: -1;
            }
        }
        .tracking-mockImage + &,
        &:first-child {
            position: relative;
            z-index: 1;

            .tracking-step-dot {
                &:before {
                    color:$white;
                    z-index: -1;
                }
                &:after {
                    content: normal;
                }
            }
        }
        &:last-child {
            .tracking-step-dot {
                &:after {
                    color: $white;
                }
            }
        }
    }

    @include media-breakpoint-up(s){
        &-step-icon {
            &_s {
                display: block;
            }
        }
        &-block {
            margin-bottom: 1.125rem;
        }
    }

    @include media-breakpoint-between(s, sm){
        &-step {
            &-icon {
                width: 64px;
                height: 64px;
                svg {
                    max-height: 100%;
                }
            }
            &-dot {
                width: 28px;
                height: 28px;
                border-width: 14px;
            }
        }
    }
    @include media-breakpoint-up(md){
        &-block {
            margin-bottom: 1.25rem;
            min-height: 80px;
        }
        &-step {
            &-icon {
                width: 60px;
                height: 60px;
                svg {
                    max-height: 100%;
                }
            }
            &-dot {
                width: 24px;
                height: 24px;
                border-width: 12px;
            }
        }
    }
    @include media-breakpoint-up(lg){
        &-block {
            margin-bottom: 1.5rem;
        }
        &-step {
            &-icon {
                width: 72px;
                height: 72px;
                svg {
                    max-height: 100%;
                }
            }
            &-dot {
                width: 28px;
                height: 28px;
                border-width: 14px;
            }
        }
    }
}